import React, { useEffect, useState, useRef } from "react";
import _, { get, set } from "lodash";
import { PrimaryButton, TextBox, Viewer } from "@react-pdf-viewer/core";
import { MobileView, isMobile } from "react-device-detect";
import { useParams } from "react-router";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/core/lib/styles/index.css";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { PDFDocument, StandardFonts, Color, rgb, degrees } from "pdf-lib";
import { FileIcon, defaultStyles } from "react-file-icon";

import { FiDownload } from "react-icons/fi";
import {
  SelectionMode,
  selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";
import axios from "axios";
const DynamicContent = ({
  formatBytes,
  size,
  file_extension,
  fileName,
  downloadFile,
  lottie,
}) => {
  const contents = [
    {
      heading: "Secure and private file sharing",
      desc: `With features such as advanced encryption, passwordprotection,
            screenshot prevention, and document expiry,ensure your
            confidential documents are securely transferredand shared the
            way you need them to be.`,
    },
    {
      heading: "Facial Recognition",
      desc: `When password protection and watermarking are not enough of a deterence, take the security of your transfer, send, and documents to the next level with facial recognition. Obtain proof of delivery and enhanced security by enabling file access via registered faces, or alternatively, enable file access only after a face has been recognized and captured.`,
    },
    {
      heading: "Automated Watermarks",
      desc: `Efficiently deter unauthorized document sharing and ensure secure file sharing with dynamic automated watermarking, offering quick, customizable settings for each document and recipient to save time and effort.`,
    },
    {
      heading: "Share Large Files Fast",
      desc: `Quickly move, share, and transfer documents, photos, videos, and other large files, at the touch of a button. With a generous storage of 3TB and a maximum file size of 50GB, you can seamlessly send and share even your largest files.`,
    },
    {
      heading: "Fine Grained Access Control",
      desc: `Easily control and transfer access to your files among team members, devices, and geo-locations.`,
    },
    {
      heading: "Detailed Analytics and Tracking",
      desc: `Keep track of who securely viewed your files, as well as the location and number of secure views, prints, and downloads.`,
    },
  ];

  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(1);
  useEffect(() => {
    setIndex1(contents[Math.floor(Math.random() * contents.length)]);
  }, []);

  return (
    <>
      <div className="hidden lg:block py-[30px] sm:py-[40px] contentBlock lg:my-[auto] -[37.6543209877vh] bg-[#ffffff08] backdrop-blur-[52px] lg:backdrop-blur-[3.61111111111vw] lg:py-[2.77777777778vw] lg:w-[40.5555555556vw] rounded-[20px] lg:rounded-[1.38888888889vw]">
        <div className="w-[92%] mx-[auto] text-[#FFF]">
          <h2 className="text40 contentHeading">Why <a
              href="https://www.grandtransfer.io/"
              target="_blank"
              className=""
            >Grandtransfer.io </a>?</h2>
          <h3 className="mt-10 contentHeading">{index1.heading}</h3>
          <p className="text16  mt-[15px] contentDesc">
            {index1.desc}
          </p>
        </div>
      </div>
    </>
  );
};

export default DynamicContent;
