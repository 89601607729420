import axios from "axios";
import { baseURL } from "./BaseUrl.constants";

const instance = axios.create({
  baseURL: baseURL,
  // baseURL: 'https://api.grandpdf.io/api',
  headers: { "Content-Type": "application/json" },
});

async function getShareLinkBySlug(slug, id, getOne) {
  instance.defaults.headers = { Accept: "application/json" };
  const currentURL = window.location.href;

  const url = new URL(currentURL);
  // Get the value of the 'user' parameter from the query string
  const userParam = url.searchParams.get('token');

  const apicall = await instance
    .post(`/get_share_link_by_slug/${slug}`, { token: userParam, id: id, getOne: getOne })
    .then((response) => {
      //// console.log(response)
      return response?.status === 200 ? response?.data : response;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        return err?.response?.data;
      } else {
        return { status: 500, message: "somthing went wrong" };
      }
    });
  return apicall;
}

const getGeoInfo = () => {
  return axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      // return unknown location if the request fails
      return {
        city: "Unknown",
        country: "Unknown",
        country_calling_code: "Unknown",
        country_name: "Unknown",
        ip: "Unknown",
        latitude: "Unknown",
        longitude: "Unknown",
        postal: "Unknown",
        region: "Unknown",
        region_code: "Unknown",
        timezone: "Unknown",
      };

      // console.log(error);
    });
};

async function getPdfdetails(id) {
  instance.defaults.headers = {
    // Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const apicall = await instance
    .get(`/get_pdf_detail/${id}`)
    .then((res) => {
      return res?.status === 200 ? res?.data : res;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        return err?.response?.data;
      } else {
        return { status: 500, message: "somthing went wrong" };
      }
    });
  return apicall;
}

async function getPdfDownloadUpadate(id, payload) {
  instance.defaults.headers = {
    // Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const apicall = await instance
    .put(`/update-links-info/${id}`, payload).then((res) => {
      return res?.status === 200 ? res?.data : res;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        return err?.response?.data;
      } else {
        return { status: 500, message: "somthing went wrong" };
      }
    });
  return apicall;
}

async function CreateStats(id, payload) {

  //// console.log(payload);

  instance.defaults.headers = {
    // Authorization: `Bearer ${token}`,

    Accept: "application/json",
  };

  const apicall = await instance
    .post(`/create_documents_stats/${id}`, payload).then((res) => {
      return res?.status === 200 ? res?.data : res;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        return err?.response?.data;
      } else {
        return { status: 500, message: "somthing went wrong" };
      }
    });
  return apicall;
}

async function getDocumentsStats(id) {
  instance.defaults.headers = {
    // Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const apicall = await instance
    .post(`/get_documents_stats`, {
      from: "null",
      is_date_filter: "false",
      share_link_id: id,
      to: "null"
    }).then((res) => {
      return res?.status === 200 ? res?.data : res;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        return err?.response?.data;
      } else {
        return { status: 500, message: "somthing went wrong" };
      }
    });
  return apicall;
}

export { getShareLinkBySlug, getGeoInfo, getPdfdetails, getPdfDownloadUpadate, CreateStats, getDocumentsStats };
