import React from "react";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import "../style.css";
import Header from "../../Layout/Header";
const DownloadLoader = ({ downloadProgress, file = true }) => {
  return (
    <>
      <div
        id="downloading"
        className="z-[10] absolute bg-[#000000] bg-opacity-[0.8] w-full h-[100vh] top-[0] left-[0] flex justify-center items-center"
      >
        <div className="z-[4] relative backdrop-blur-[52px] lg:backdrop-blur-[3.61111111111vw] py-[30px] sm:py-[40px] bg-[#ffffff08] lg:py-[2.77777777778vw] w-[90%] lg:w-[40.5555555556vw] rounded-[20px] lg:rounded-[1.38888888889vw]">
          <div className="w-[87%] lg:w-[92%] mx-[auto] text-[#FFF] lg:gap-y-[1.45833333333vw] gap-y-[20px] flex flex-col">
            <h2 className="text-2xl">Downloading...</h2>
            <div className="flex items-center gap-x-[15px] lg:gap-x-[1.73611111111vw]">
              <div className="ld__border bg-[#6b728033] lg:rounded-[0.13888888888vw] rounded-[2px] lg:w-[27.7777777778vw] h-[4px] lg:h-[0.27777777777vw] w-[80%]">
                <span
                  className="block  bg-[#FF50A8] lg:rounded-[0.13888888888vw] rounded-[2px] h-[4px] lg:h-[0.27777777777vw] w-[0%]"
                  style={{ width: `${Number(downloadProgress)}%` }}
                ></span>
              </div>
              <span className="text16 loadingPercentage">
                {downloadProgress}%
              </span>
              <img
                className="w-[16px] lg:w-[1.2vw] opacity-0 transition-all duration-300 tick"
                src="/assets/images/tick.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <Header />
    </>
  );
};

export default DownloadLoader;
