export const INITIAL_VALUE = {
  waterMarkType: "text",
  text: "",
  color: "#000",
  fontSize: "39",
  renderType: "on_main_page",
  imageFile: "",
  opacity: 1,
  customMark: [{ pageno: "", watermark: "" }]
};

// export const FilterValidationSchema = yup.object().shape({
//   text: yup.string().trim().required('this field is required'),
//   fontSize: yup.string().trim().required(),
// });

export const WatermarkRadios = [
  { label: "Text", value: "text" },
  { label: "Image", value: "image" },
];

export const RanderTypeRadio = [
  { label: "Just Main Page", value: "on_main_page" },
  { label: "Rest of pages", value: "on_all_page" },
];
