import { Worker } from '@react-pdf-viewer/core'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import { Analytics } from '@vercel/analytics/react';

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>

    <BrowserRouter>
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.3.122/pdf.worker.min.js">
        <App />
        <Analytics />
      </Worker>
    </BrowserRouter>
  // </React.StrictMode>,
)
