import React from "react";
import { BounceLoader } from 'react-spinners';
import ProgressBar from "@ramonak/react-progress-bar";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Header from "../page/Layout/Header";
const Loader = ({ message, downloadProgress, progress = false }) => {
  const primaryColor = "#992861";

  let progrss = 0;


  if (downloadProgress == NaN || downloadProgress == 0 || downloadProgress == null && progress) {
    progrss = 99;
  }




  return (
    <>
      <div
        id="downloading"
        className="z-[10] absolute bg-[#000000] bg-opacity-[0.8] w-full h-[100vh] top-[0] left-[0] flex justify-center items-center"
      >
        <div
          className="z-[4] relative backdrop-blur-[52px] lg:backdrop-blur-[3.61111111111vw] py-[30px] sm:py-[40px] bg-[#ffffff08] lg:py-[2.77777777778vw] w-[90%] lg:w-[40.5555555556vw] rounded-[20px] lg:rounded-[1.38888888889vw]"
        >
          <div
            className="w-[87%] lg:w-[92%] mx-[auto] text-[#FFF] lg:gap-y-[1.45833333333vw] gap-y-[20px] flex flex-col"
          >
            {
              (downloadProgress && !progress) ?
                <>
                  <h2 className="text-2xl">Loading...</h2>
                  <p className="text16">{message ? message : "Kindly hold on as we get the content ready for you"}</p>

                  <div
                    className="flex items-center gap-x-[15px] lg:gap-x-[1.73611111111vw]"
                  >
                    <div
                      className="ld__border bg-[#6b728033] lg:rounded-[0.13888888888vw] rounded-[2px] lg:w-[27.7777777778vw] h-[4px] lg:h-[0.27777777777vw] w-[80%]"
                    >
                      <span
                        className="block  bg-[#FF50A8] lg:rounded-[0.13888888888vw] rounded-[2px] h-[4px] lg:h-[0.27777777777vw] w-[0%]"
                        style={{
                          width: `${downloadProgress}%`
                        }}
                      ></span>
                    </div>
                    <span className="text16 loadingPercentage">{downloadProgress}%</span>
                    <img
                      className="w-[16px] lg:w-[1.2vw] opacity-0 transition-all duration-300 tick"
                      src="/assets/images/tick.svg"
                    />
                  </div>
                </> : <>
                  {progress ?
                    <>
                      <p className="text16">{message ? message : "Almost there, please wait a moment"}</p>

                      <div
                        className="flex items-center gap-x-[15px] lg:gap-x-[1.73611111111vw]"
                      >
                        <div
                          className="ld__border bg-[#6b728033] lg:rounded-[0.13888888888vw] rounded-[2px] lg:w-[27.7777777778vw] h-[4px] lg:h-[0.27777777777vw] w-[80%]"
                        >
                          <span
                            className="block  bg-[#FF50A8] lg:rounded-[0.13888888888vw] rounded-[2px] h-[4px] lg:h-[0.27777777777vw] w-[0%]"
                            style={{
                              width: `${99}%`
                            }}
                          ></span>
                        </div>
                        <span className="text16 loadingPercentage">{99}%</span>
                        <img
                          className="w-[16px] lg:w-[1.2vw] opacity-0 transition-all duration-300 tick"
                          src="/assets/images/tick.svg"
                        />
                      </div>


                    </> : <>
                      <h2 className="text-2xl">Loading...</h2>
                      <p className="text16">{message ? message : "Kindly hold on as we get the content ready for you"}</p>

                    </>

                  }

                </>
            }


          </div>
        </div>
      </div>
     <Header/>
      <section className="h-[100vh]">
        <div
          className="flex items-center pt-[40px] lg:pt-[3.5vw] h-full w-full px-[8%] sm:px-[5%] overflow-hidden relative"
        >
          <img
            src="/assets/images/BG.jpg"
            alt="BG"
            className="h-full w-full absolute top-0 left-0 object-cover z-[-1]"
          />
          
         
        </div>
      </section>
    </>
  );
};

export default Loader;
