import React, { useEffect, useState, useRef } from "react";
import ViewerWrapper from "./components/ViewerWrapper";
import _, { get, set } from "lodash";
import useWaterMarkHook from "./components/WaterMark.hook";
import { PrimaryButton, TextBox, Viewer } from "@react-pdf-viewer/core";
import { MobileView, isMobile } from "react-device-detect";
import { useParams } from "react-router";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/core/lib/styles/index.css";
import LandingPage from "./components/LandingPage";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { PDFDocument, StandardFonts, Color, rgb, degrees } from "pdf-lib";
import { FileIcon, defaultStyles } from "react-file-icon";
import { RotatingLines } from "react-loader-spinner";

import Loader from "../../components/Loader";
import { FiDownload } from "react-icons/fi";
import {
  SelectionMode,
  selectionModePlugin
} from "@react-pdf-viewer/selection-mode";
import { ProxyUrl, S3BaseUrl } from "../../service/Api/BaseUrl.constants";
import axios from "axios";
import PdfViewer from "./components/PdfViewer";
import PasswordDialog from "./components/PasswordDialog";
import DownloadLoader from "./components/DowloadLoader";
import DetectPhotoComponent from "./components/DetectPhotoComponent";
import "./style.css";
import Header from "../Layout/Header";

import DynamicContent from "../Layout/DynamicContent";
const File = ({
  formatBytes,
  size,
  file_extension,
  fileName,
  slugFileData,
  downloadFile,
  fileUrl,
  imageSrc,
  lottie,
  isImageLoaded,
  progress
}) => {
  const contents = [
    {
      heading: "Secure and private file sharing",
      desc: `   With features such as advanced encryption, passwordprotection,
            screenshot prevention, and document expiry,ensure your
            confidential documents are securely transferredand shared the
            way you need them to be.`
    },
    {
      heading: "Facial recognition",
      desc: `When password protection and watermarking are not enough of a deterence, take the security of your transfer, send, and documents to the next level with facial recognition. Obtain proof of delivery and enhanced security by enabling file access via registered faces, or alternatively, enable file access only after a face has been recognized and captured.`
    },
    {
      heading: "Automated watermarks",
      desc: `Efficiently deter unauthorized document sharing and ensure secure file sharing with dynamic automated watermarking, offering quick, customizable settings for each document and recipient to save time and effort.`
    },
    {
      heading: "  Share large files fast",
      desc: `Quickly move, share, and transfer documents, photos, videos, and other large files, at the touch of a button. With a generous storage of 3TB and a maximum file size of 50GB, you can seamlessly send and share even your largest files.`
    },
    {
      heading: "Fine grained access control",
      desc: `Easily control and transfer access to your files among team members, devices, and geo-locations.`
    },
    {
      heading: "Detailed analytics and tracking",
      desc: `Keep track of who securely viewed your files, as well as the location and number of secure views, prints, and downloads.`
    }
  ];

  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(1);
  useEffect(() => {
    setIndex1(contents[Math.floor(Math.random() * contents.length)]);
  }, []);

  // console.log();

  return (
    <>
      <>
        <Header />
        <section className="h-[100vh]">
          <div className="flex items-center pt-[40px] lg:pt-[3.5vw] h-full w-full px-[8%] sm:px-[5%] overflow-hidden relative">
            <img
              src="/assets/images/BG.jpg"
              alt="BG"
              className="h-full w-full absolute top-0 left-0 object-cover z-[-1]"
            />
            <div className="flex  justify-between w-full items-center lg:items-end flex-wrap flex-col-reverse lg:flex-row lg:gap-y-[unset] sm:gap-y-[120px] gap-y-[60px]">
              <div className="relative w-[100%] downloadBlock sm:w-[80%] max-h-[85vh] overflow-auto pb-[20px] lg:pb-[2.30555555556vw] lg:w-[26.0416666667vw] lg:rounded-[1.04166666667vw] rounded-[15px] bg-[#FFF]">
                <div className="mt-[20px] sm:mt-[20px] lg:mt-[1.69444444444vw] flex flex-col gap-y-[15px] lg:gap-y-[1.52777777778vw]"></div>
                <div
                  className="w-[100%] px-[4%]"
                  style={{
                    overflowX: "hidden"
                  }}
                >
                  <div className="flex flex-col gap-y-[6px] lg:gap-y-[0.48611111111vw] z-[5] items-center sticky top-[0vw] pb-[20px] lg:pb-[1.6vw] pt-[20px] lg:pt-[1.66666666667vw] bg-[#fff]">
                    <img
                      src="/assets/images/down.svg"
                      alt="down"
                      className="lg:w-[4.11777777778vw] lg:h-[6.18604166667vw] w-[50px]"
                    />
                    {slugFileData.document_details.file_type == "image" ? (
                      <>
                        {isImageLoaded ? (
                          <>
                            <img
                              src={imageSrc}
                              alt={fileName + "." + file_extension}
                              className=" w-full max-h-[200px] lg:max-h-[20vw] object-cover"
                            />
                          </>
                        ) : (
                          <>
                            <RotatingLines
                              visible={true}
                              height="60"
                              width="50"
                              color="red"
                              strokeColor="#000"
                              strokeWidth="2"
                              animationDuration="0.75"
                              ariaLabel="rotating-lines-loading"
                              wrapperStyle={{
                                stroke: "red"
                              }}
                              wrapperclassName=""
                            />

                            <span className="text14 text-[#000000]">
                              Loading...({progress}%)
                            </span>
                          </>
                        )}
                      </>
                    ) : null}

                    <div className="flex justify-center items-center mt-4">
                      <div className="flex items-center gap-1  lg:mt-[0.194vw] mb-2 lg:mb-[0.5vw]">
                        <div className="customIconSize mb-2 mx-1">
                          <FileIcon
                            extension={file_extension}
                            {...defaultStyles[file_extension]}
                          />
                        </div>
                        <div>
                          <div className="flex w-full flex-col">
                            <span
                              data-file-name={fileName + "." + file_extension}
                              className="text14 fileNameSpan text-[#000000]"
                            >
                              {fileName.length > 20
                                ? fileName.substring(0, 20) + "..."
                                : fileName}
                              {"." + file_extension}
                            </span>
                          </div>
                          <span className="text-xl inter-regular"></span>
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={downloadFile}
                      className="px-[20px] py-[8px] sm:min-w-[unset] min-w-[200px] hover:bg-[#822252] transition-all duration-300 flex lg:px-[0.90277777777vw] lg:py-[0.48611111111vw] bg-[#992861] lg:items-center lg:gap-x-[0.34722222222vw] gap-x-[5px] lg:rounded-[0.41666666666vw] rounded-[6px]
                     
                      "
                    >
                      <img
                        src="/assets/images/down-white.svg"
                        alt="down"
                        className="lg:w-[2.1111111111vw] btn-svg-icon"
                      />
                      <span className="sm:text-[20px] text-[13px] text-[#FFF] ">
                        Download ({formatBytes(size)})
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <DynamicContent />
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default File;
