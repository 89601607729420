import { TextBox } from "@react-pdf-viewer/core";
import React from "react";
import Cookies from "js-cookie";
import Password from "../Password";

const PasswordDialog = ({
  slugFileData,
  error,
  setError,
  setOpenPdfByPassword,
}) => {
  const [password, setPassword] = React.useState("");

  const submit = (e) => {

    e.preventDefault(); // Prevent the default form submission behavior

    if (slugFileData?.password === password) {
      setError(false);
      setOpenPdfByPassword((res) => ({ ...res, passwordMatch: true }));
      Cookies.set("password", password, { expires: 7 });
    } else {
      setError(true);
      setOpenPdfByPassword((res) => ({ ...res, passwordMatch: false }));
    }
  };

  //// console.log("password", password);

  return (
    <>
      <Password password={password} submit={submit} setPassword={setPassword} error={error} />

    </>
  );
};

export default React.memo(PasswordDialog);