import React, { useEffect, useState, useRef } from "react";
import ViewerWrapper from "./components/ViewerWrapper";
import _, { get, set } from "lodash";
import useWaterMarkHook from "./components/WaterMark.hook";
import { PrimaryButton, TextBox, Viewer } from "@react-pdf-viewer/core";
import { MobileView, isMobile } from "react-device-detect";
import { useParams } from "react-router";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/core/lib/styles/index.css";
import LandingPage from "./components/LandingPage";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { PDFDocument, StandardFonts, Color, rgb, degrees } from "pdf-lib";

import Loader from "../../components/Loader";
import { FiDownload } from "react-icons/fi";
import {
    SelectionMode,
    selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";
import { ProxyUrl, S3BaseUrl } from "../../service/Api/BaseUrl.constants";
import axios from "axios";
import PdfViewer from "./components/PdfViewer";
import PasswordDialog from "./components/PasswordDialog";
import DownloadLoader from "./components/DowloadLoader";
import DetectPhotoComponent from "./components/DetectPhotoComponent";
import './style.css';
import Header from "../Layout/Header";
const Password = ({
    error,
    password,
    submit,
    setPassword

}) => {




    return (

        <>
            <div
                id="login"
                className="z-[2] absolute top-[50%] left-[50%] transform translate-y-[-50%] translate-x-[-50%] py-[30px] sm:py-[40px] bg-[#ffffff08] backdrop-blur-[52px] lg:backdrop-blur-[3.61111111111vw] lg:py-[2.77777777778vw] w-[90%] lg:w-[40.5555555556vw] rounded-[20px] lg:rounded-[1.38888888889vw]"
            >
                <div
                    id="loginForm"
                    className="items-center w-[87%] lg:w-[85%] mx-[auto] text-[#FFF] lg:gap-y-[1.45833333333vw] gap-y-[20px] flex flex-col"
                >
                    <p className="text16">
                        To open please enter the password got shared with you?
                    </p>
                    <div
                        className="flex items-center gap-x-[15px] lg:gap-x-[1.73611111111vw] w-full"
                    >
                        <form className="w-full">
                            <div
                                className="relative z-[3] gap-y-[8px] lg:gap-y-[1.2vw] flex items-center justify-center flex-col w-[100%]"
                            >
                                <input
                                    type="password"
                                    name=""
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    id=""
                                    className="bg-[#15141c54] text-white border-0 outline-none px-[12px] py-[6px] w-[100%] lg:py-[0.5vw] lg:px-[1vw] outline-none w-full lg:rounded-[0.41666666666vw] rounded-[6px]"
                                />
                                {error && (
                                    <div className="w-full h-full warning">
                                        <i className="fa-solid fa-triangle-exclamation"></i> The password
                                        is invalid. Please try again!
                                    </div>
                                )}
                                <button
                                    disabled={password.length < 1}
                                    onClick={submit}
                                    className="text16 bg-[#992861] px-[32px] hover:bg-[#822252] transition-all duration-300 py-[8px] flex lg:px-[1.50277777777vw] lg:py-[0.5vw] lg:rounded-[0.41666666666vw] rounded-[6px]"
                                >
                                    Proceed
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

            <Header />
            <section className="h-[100vh]">
                <div
                    className="flex items-center pt-[40px] lg:pt-[3.5vw] h-full w-full px-[8%] sm:px-[5%] overflow-hidden relative"
                >
                    <img
                        src="/assets/images/BG.jpg"
                        alt="BG"
                        className="h-full w-full absolute top-0 left-0 object-cover z-[-1]"
                    />

                </div>
            </section>
        </>
    );

};

export default Password;
