import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Header from '../../Layout/Header';

export default function LandingPage({ msg }) {




  return (
    <>
      <div
        id="login"
        className="z-[2] absolute top-[50%] left-[50%] transform translate-y-[-50%] translate-x-[-50%] py-[30px] sm:py-[40px] bg-[#ffffff08] backdrop-blur-[52px] lg:backdrop-blur-[3.61111111111vw] lg:py-[2.77777777778vw] w-[90%] lg:w-[40.5555555556vw] rounded-[20px] lg:rounded-[1.38888888889vw]"
      >

        <div
          id="loginForm"
          className="items-center w-[87%] lg:w-[85%] mx-[auto] text-[#FFF] lg:gap-y-[1.45833333333vw] gap-y-[20px] flex flex-col"
        >
          <svg className="flex-shrink-0 inline w-10 h-10 me-3 text-red-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <p className="text18  text-red-300">
            {msg}
          </p>

        </div>

      </div>
      <div
        id="downloading"
        className="hidden z-[10] absolute bg-[#000000] bg-opacity-[0.8] w-full h-[100vh] top-[0] left-[0] flex justify-center items-center"
      >
        <div className="z-[4] relative backdrop-blur-[52px] lg:backdrop-blur-[3.61111111111vw] py-[30px] sm:py-[40px] bg-[#ffffff08] lg:py-[2.77777777778vw] w-[90%] lg:w-[40.5555555556vw] rounded-[20px] lg:rounded-[1.38888888889vw]">
          <div className="w-[87%] lg:w-[92%] mx-[auto] text-[#FFF] lg:gap-y-[1.45833333333vw] gap-y-[20px] flex flex-col">
            <h2 className="text-2xl">Downloading...</h2>
            <div className="flex items-center gap-x-[15px] lg:gap-x-[1.73611111111vw]">
              <div className="ld__border bg-[#6b728033] lg:rounded-[0.13888888888vw] rounded-[2px] lg:w-[27.7777777778vw] h-[4px] lg:h-[0.27777777777vw] w-[80%]">
                <span className="block loadingState bg-[#FF50A8] lg:rounded-[0.13888888888vw] rounded-[2px] h-[4px] lg:h-[0.27777777777vw] w-[0%]" />
              </div>
              <span className="text16 loadingPercentage">0%</span>
              <img
                className="w-[16px] lg:w-[1.2vw] opacity-0 transition-all duration-300 tick"
                src="/assets/images/tick.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <Header />
      <section className="h-[100vh]">
        <div className="flex items-center pt-[40px] lg:pt-[3.5vw] h-full w-full px-[8%] sm:px-[5%] overflow-hidden relative">
          <img
            src="/assets/images/BG.jpg"
            alt="BG"
            className="h-full w-full absolute top-0 left-0 object-cover z-[-1]"
          />
          <div className="flex contentArea justify-between w-full items-center lg:items-end flex-wrap flex-col-reverse lg:flex-row lg:gap-y-[unset] sm:gap-y-[120px] gap-y-[60px]">
            <div className="relative w-[100%] downloadBlock sm:w-[80%] max-h-[85vh] overflow-auto pb-[20px] lg:pb-[2.30555555556vw] lg:w-[26.0416666667vw] lg:rounded-[1.04166666667vw] rounded-[15px] bg-[#FFF]">
            
            
            </div>
            <div className="py-[30px] sm:py-[40px] contentBlock lg:my-[auto] -[37.6543209877vh] hidden lg:block bg-[#ffffff08] backdrop-blur-[52px] lg:backdrop-blur-[3.61111111111vw] lg:py-[2.77777777778vw] lg:w-[40.5555555556vw] rounded-[20px] lg:rounded-[1.38888888889vw]">
              <div className="w-[92%] mx-[auto] text-[#FFF]">
                <h2 className="text40 contentHeading" />
                <p className="text16 lg:mt-[1.45833333333vw] mt-[20px] contentDesc" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

    // <div className="bg-white">

    //   {/* Header */}
    //   <header className="absolute inset-x-0 top-0 z-50">
    //     <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
    //       <div className="flex lg:flex-1">
    //         <a href="https://www.grandtransfer.io/" target="_blank" className="-m-1.5 p-1.5">
    //           <span className="sr-only">GrandTransfer.io</span>
    //           <img
    //             className="h-10 w-auto"
    //             src="/GP-Logo-colored.svg"
    //             alt=""
    //           />
    //         </a>
    //       </div>
    //     </nav>
    //   </header>

    //   <main className="isolate">

    //     {/* Hero section */}
    //     <div className="relative pt-14">
    //       <div className="py-24 sm:py-32">
    //         <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
    //           <svg
    //             className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
    //             viewBox="0 0 1155 678"
    //           >
    //             <path
    //               fill="url(#9b2541ea-d39d-499b-bd42-aeea3e93f5ff)"
    //               fillOpacity=".3"
    //               d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
    //             />
    //             <defs>
    //               <linearGradient
    //                 id="9b2541ea-d39d-499b-bd42-aeea3e93f5ff"
    //                 x1="1155.49"
    //                 x2="-78.208"
    //                 y1=".177"
    //                 y2="474.645"
    //                 gradientUnits="userSpaceOnUse"
    //               >
    //                 <stop stopColor="#9089FC" />
    //                 <stop offset={1} stopColor="#FF80B5" />
    //               </linearGradient>
    //             </defs>
    //           </svg>
    //         </div>
    //         <div className="mx-auto max-w-7xl px-6 lg:px-8">
    //           <div className="mx-auto max-w-2xl text-center">
    //             {!msg
    //               ?
    //               <>
    //                 <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
    //                   File Viewer
    //                 </h1>
    //                 <p className="mt-6 text-lg leading-8 text-gray-600"> Here you can view file using url slug.</p>
    //               </>
    //               :
    //               <div className="w-full h-full warning">
    //                 <i className="fa-solid fa-triangle-exclamation"></i> {msg}
    //               </div>
    //             }

    //           </div>

    //         </div>

    //       </div>
    //     </div>

    //     {/* Logo cloud */}


    //     {/* Pricing section */}


    //     {/* FAQs */}


    //     {/* CTA section */}

    //   </main>

    //   {/* Footer */}

    // </div>
  )
}