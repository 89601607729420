import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import _ from "lodash";
import Draggable from "./Draggable";
import { INITIAL_VALUE } from "./Watermark.constants";
import Cookies from 'js-cookie';
import { CreateStats, getDocumentsStats, getPdfDownloadUpadate, getShareLinkBySlug } from "../../../service/Api";
import { MobileView, isMobile } from "react-device-detect";
import moment from "moment";
import html2canvas from "html2canvas";
import { useParams } from 'react-router-dom';

const useWaterMarkHook = (props) => {
    const { user } = useParams();

    const { slug, geoLocationData } = props;

    const queryString = window.location.search;

    // Create a new URLSearchParams object
    const params = new URLSearchParams(queryString);

    // Access specific query parameters
    const id = params.get('id');



    const [preview, setPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [storedSelectedData, setStoredSelectedData] = useState(INITIAL_VALUE);
    const [textWidth, setTextWidth] = useState([]);

    const [openPdfByPassword, setOpenPdfByPassword] = React.useState({
        passwordAvailable: false,
        passwordMatch: false
    });
    const [openPdfByface, setOpenPdfByface] = React.useState({
        faceAvailable: false,
        faceai: false,
        faceMatch: false,
        photo_url: ''
    });
    const [displayView, setDisplayView] = React.useState(null);
    const [slugFileData, setSlugFileData] = useState(null);
    const [addWaterMarkFields, setAddWaterMarkFields] = useState({});
    const [slugInvalidError, setSlugInvalidError] = useState("");
    const [downloadAccess, setDownloadAccess] = useState(false);
    const [load, setLoad] = useState(false)
    // const [isLoading, setIsLoading] = useState(false);
    const [notAvailableInCountry, setNotAvailableInCountry] = useState(false);

    useEffect(() => {
        if (slug && geoLocationData?.countryName) {
            setIsLoading(true)
            getShareLinkBySlug(slug, id).then((res) => {

                if (res?.success) {
                    const decodedData = atob(res?.data?.base64);

                    setSlugFileData({
                        ...res.data,
                        base64: decodedData, // Assuming base64 is a key in res.data
                    });
                    setLoad(true)


                    if (res?.data?.document_details?.watermark_data) {
                        setAddWaterMarkFields(JSON.parse(res?.data?.document_details?.watermark_data));
                    }
                    if (
                        slugFileData &&
                        (slugFileData?.selectCountries === "true" ||
                            res?.data?.geo_locations?.includes(geoLocationData?.countryName))
                    ) {
                        //// console.log('true>>>>>setNotAvailableInCountry');
                        setNotAvailableInCountry(true);
                    }
                    else {
                        //// console.log('false>>>>>')

                        setNotAvailableInCountry(false)
                    }
                } else {
                    setSlugInvalidError(res?.message);
                   
                }
                setIsLoading(false)
                setLoad(true)
            }).catch((err) =>
                 console.log(err));
        }
    }, [slug, geoLocationData?.countryName, slugFileData?.geo_locations])




    useEffect(() => {

        if (slugFileData) {
            const getPassword = Cookies.get('password');
            // if (getPassword && getPassword === slugFileData?.password) {
            //     // setPassword(getPassword);
            //     setOpenPdfByPassword((res) => ({ ...res, passwordMatch: true }));
            // }

            if (slugFileData?.password !== '' && slugFileData?.password !== undefined && slugFileData?.password !== null) {
                setOpenPdfByPassword((res) => ({ ...res, passwordAvailable: true }));

            }

            if (slugFileData?.take_photo == 'true') {
                setOpenPdfByface(prevState => ({ ...prevState, faceAvailable: true }));
            }

            if (slugFileData?.ai_option == "With") {
                setOpenPdfByface((res) => ({ ...res, faceai: true }));

            }
            if (slugFileData?.show_pdf_on === "mobile")
                setDisplayView('Mobile');

            if (slugFileData?.show_pdf_on === "both")
                setDisplayView('Both');

            if (slugFileData?.show_pdf_on === "desktop")
                setDisplayView('Web');


            if (slugFileData?.show_with_water_mark === 'true')
                setPreview(true);

            if (slugFileData?.download === "true")
                setDownloadAccess(true);

        }
    }, [slugFileData?.password])


    useEffect(() => {

        //// console.log('testca',notAvailableInCountry , displayView, slugFileData?.password  , openPdfByPassword?.passwordMatch )

        // console.log('test', displayView, ((slugFileData?.password !== '' && slugFileData?.password !== undefined && slugFileData?.password !== null) ? openPdfByPassword?.passwordMatch : true))
        // document.getElementsByClassName('rpv-core__inner-pages').setAttribute('id', 'canvas_data')
        if (slugFileData && notAvailableInCountry &&
            displayView &&
            ((slugFileData?.password !== '' && slugFileData?.password !== undefined && slugFileData?.password !== null) ?
                openPdfByPassword?.passwordMatch : true)
            &&
            ((slugFileData?.take_photo == "true" ? openPdfByface?.faceMatch : true))

        ) {
            const { id } = slugFileData;
            const currentURL = window.location.href;
            // Parse the URL to extract query parameters
            const url = new URL(currentURL);
            // Get the value of the 'user' parameter from the query string
            const userParam = url.searchParams.get('token');
            // console.log(slugFileData, "slugFileDataslugFileData")
            //// console.log("cvbngc")
            CreateStats(id, {
                "display_on": isMobile ? "Mobile" : "desktop",
                "token": userParam,
                "display_on_country": notAvailableInCountry ? geoLocationData?.countryName : '',
                "download_count": "0",
                "photo_url": openPdfByface.photo_url,
                "pending_view_limit": slugFileData?.pending_view_limit - 1,
                "type": slugFileData.type === "folder" ? "folder" : "file",
                "folderId": slugFileData.document_upload_id
            })
        }


    }, [openPdfByPassword?.passwordMatch, openPdfByface?.faceMatch, slugFileData])






    useEffect(() => {
        if (addWaterMarkFields) {
            setStoredSelectedData(addWaterMarkFields?.fieldData);
            const ArrayValues = [];
            _.map(addWaterMarkFields?.directionData, ({ pageIndex, topCordinate, left, rotation, width, height }) => {
                ArrayValues.push({ pageIndex: pageIndex, topCordinate, left, rotation, width, height })
            })
            setTextWidth(ArrayValues);
        }
    }, [addWaterMarkFields]);

    const ARRAYDATA = addWaterMarkFields?.directionData?.length > 0 ? addWaterMarkFields?.directionData : []
    let ARRAY_VALUE = [...ARRAYDATA];
    // let ARRAY_VALUE = [];
    function renderDragable(props, FINDVALUE) {
        return (
            <>
                {(preview) &&
                    (storedSelectedData?.text || storedSelectedData?.imageFile) && (
                        <Draggable
                            {...{
                                watermark: (storedSelectedData?.customMark?.length > 0 && FINDVALUE) ? FINDVALUE : storedSelectedData?.text || storedSelectedData?.imageFile,
                                image: storedSelectedData?.imageFile,
                                pageIndex: props?.pageIndex,
                                ARRAY_VALUE,
                                textWidth,
                                setTextWidth,
                                hideReactangle: preview,
                                color: storedSelectedData?.color,
                                opacity: storedSelectedData?.opacity,
                                fontSize: storedSelectedData?.fontSize,
                            }}
                        />
                    )}
            </>
        );
    }

    const printRef = React.useRef();
    const handleDownloadPdf = async () => {
        // const element = printRef.current;
        // //// console.log(element, "ddddddddddddddddddd");
        // const canvas = await html2canvas(element);
        // const data = canvas.toDataURL('image/png');

        // const pdf = new jsPDF();
        // const imgProperties = pdf.getImageProperties(data);
        // const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight =
        //     (imgProperties.height * pdfWidth) / imgProperties.width;

        // pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        // pdf.save('print.pdf');
        var data = document.querySelectorAll('.rpv-core__inner-page');
        const pdf = new jsPDF();
        //// console.log(data?.length);
        for (let i = 0; i <= data?.length; i++) {
            const canvas = await html2canvas(data[i]);
            const value = canvas.toDataURL('image/png');
            //// console.log(data?.length, i);

            const imgProperties = pdf.getImageProperties(value);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;

            pdf.addImage(value, 'PNG', 0, 0, pdfWidth, pdfHeight);
            if (i === data?.length) {
                //// console.log('hello')
                pdf.save('print.pdf');
            }

        }


        // //// console.log(data, "dddddddddddddddddd");
        // html2canvas(data).then((canvas) => {
        //     const doc = new jsPDF('p', 'mm');
        //     const imgProperties = doc.getImageProperties(canvas);
        //     const imgWidth = doc.internal.pageSize.getWidth();
        //     const imgHeight =
        //         (imgProperties.height * imgWidth) / imgProperties.width;

        //     const pageHeight = 300;
        //     // const imgHeight = (canvas.height * imgWidth) / canvas.width;
        //     let heightLeft = imgHeight;
        //     let position = 0;
        //     heightLeft -= pageHeight;
        //     doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        //     while (heightLeft >= 0) {
        //         position = heightLeft - imgHeight;
        //         doc.addPage();
        //         doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        //         heightLeft -= pageHeight;
        //     }
        //     doc.save('Downld.pdf');
        // });
    };

    const renderPage = (props, section, ref) => {
        const FINDVALUE = _.find(storedSelectedData?.customMark, {
            pageno: String(props?.pageIndex + 1),
        })?.watermark;
        const CHECKARRAY = _.find(ARRAY_VALUE, { pageIndex: props?.pageIndex });

        let defaultValues = {
            water_mark_type: storedSelectedData?.waterMarkType,
            watermark_content: (FINDVALUE && FINDVALUE?.length > 0) ? FINDVALUE : storedSelectedData?.text || storedSelectedData?.imageFile,
            topCordinate: _.find(textWidth, { pageIndex: props?.pageIndex })?.topCordinate || 100,
            left: _.find(textWidth, { pageIndex: props?.pageIndex })?.left || 310,
            rotation: _.find(textWidth, { pageIndex: props?.pageIndex })?.rotation || 0,
            fontsize: +storedSelectedData?.fontSize,
            pageIndex: props?.pageIndex,
            opacity: storedSelectedData?.opacity,
            color: storedSelectedData?.color,
            width: _.find(textWidth, { pageIndex: props?.pageIndex })?.width || 250,
            height: _.find(textWidth, { pageIndex: props?.pageIndex })?.height || 120
        }

        if (CHECKARRAY) {
            _.map(_.entries(defaultValues), ([key, values]) => {
                CHECKARRAY[key] = values;
            });
            const REJECTED_DATA = _.reject(ARRAY_VALUE, CHECKARRAY);
            ARRAY_VALUE = [...REJECTED_DATA, CHECKARRAY];
        } else {
            ARRAY_VALUE.push(defaultValues);
        }

        return (
            <div div className="hell_of_you">
                {props.canvasLayer.children}
                {(() => {
                    if (storedSelectedData?.renderType === "on_main_page") {
                        if (props?.pageIndex === 0) {
                            return renderDragable(props, FINDVALUE);
                        }
                    } else {
                        return renderDragable(props, FINDVALUE);
                    }
                })()}
                {storedSelectedData?.renderType === "" && props?.pageIndex === 0}

                {props.annotationLayer.children}
                {props.textLayer.children}
            </div>
        );
    };

    async function getPdf(rowData, again = false) {
        const { id } = slugFileData;
        const currentURL = window.location.href;

        let type = 'file'
        let name = slugFileData?.name

        if (rowData.type) {
            type = rowData.type
            name = rowData.name
        }

        // Parse the URL to extract query parameters
        const url = new URL(currentURL);

        // Get the value of the 'user' parameter from the query string
        const userParam = url.searchParams.get('token');

        CreateStats(id, {
            display_on: isMobile ? "Mobile" : "desktop",
            display_on_country: "",
            token: userParam,
            download_count: "1",
            again: again,
            type: type,
            name: name,
            folderId: rowData.folderId,
            photo_url: openPdfByface.photo_url,
            pending_view_limit: slugFileData?.pending_view_limit,
        });
        return null;


    }

    async function addWaterMark(doc, rowData) {
        var totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            const VALUES = _.find(rowData, { pageIndex: i - 1 });
            const textValue = VALUES ? VALUES?.watermark_content : "watermark";
            const left = VALUES ? VALUES?.left : 310;
            const TOP = VALUES ? VALUES?.topCordinate : 100;
            const ROTATION = VALUES ? VALUES?.rotation : 0;
            const WIDTH = _.find(textWidth, { pageIndex: i - 1 })?.width || 50;
            const HEIGHT = _.find(textWidth, { pageIndex: i - 1 })?.height || 50;
            if (VALUES?.water_mark_type !== "image") {
                doc.setTextColor(VALUES?.color);
                doc.setFontSize(VALUES.fontsize)
                doc.text(textValue, (left / 5.2) + 10, (TOP / 5) + 12, ROTATION / 3);
                // doc.text(textValue, 150, 250, ROTATION);

            } else {

                doc.setGState(new doc.GState({ opacity: VALUES.opacity }));
                // doc.addImage(textValue, "PNG", 160, 225, (WIDTH / 9) + 9, (HEIGHT / 9) + 7, '', '', ROTATION / 8);

                doc.addImage(textValue, "PNG", (left / 4.9), (TOP / 5.3), (WIDTH / 9) + 9, (HEIGHT / 9) + 7, '', '', ROTATION / 3);
            }
        }
        return doc;
    }

    return {
        preview, setPreview, openPdfByPassword, setOpenPdfByPassword, setOpenPdfByface, openPdfByface,
        isLoading, setIsLoading, slugFileData, handleDownloadPdf,
        storedSelectedData, setStoredSelectedData, downloadAccess,
        textWidth, setTextWidth, displayView, notAvailableInCountry,
        getPdf, renderPage, ARRAY_VALUE, slugInvalidError
    }
}

export default useWaterMarkHook;