import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../page/Home'

function Routers(props) {
    return (
        <Routes>
            <Route path='/' element={<Home  {...props} />}>
                <Route path='/:username/:slug' element={<Home  {...props} />} />
            </Route>
        </Routes>
    )
}

export default React.memo(Routers);