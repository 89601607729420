import React, { useEffect, useState, useRef } from "react";
import ViewerWrapper from "./ViewerWrapper";
import { PDFDocument, StandardFonts, Color, rgb, degrees } from 'pdf-lib';
import FileViewer from 'react-file-viewer';
import ProgressBar from 'react-progress-bar-plus';
import 'react-progress-bar-plus/lib/progress-bar.css';
import Loader from "../../../components/Loader";
import axios from "axios";
import FolderView from "./FolderView";

function PdfViewer({
  openPdfByPassword,
  slugFileData,
  preview,
  selectionModePluginInstance,
  getFilePluginInstance,
  handleDownload,
  PasswordDialog,
  getPdf,
  file,
}) {


  const [modifiedPDF, setModifiedPDF] = useState(null);
  const [pdfW, setPdfw] = useState(500);
  const [pdfh, setPdfh] = useState(842);
  const [url, seturl] = useState(null);
  const [ex, setex] = useState(null);
  const [font_color, setfont_color] = useState("");
  const [name, setName] = useState('');
  const [document_id, setDocumentId] = useState('');
  const [type, setType] = useState('');
  const [content, setContent] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [left, setLeft] = useState('');
  const [top, setTop] = useState('');
  const [rotate_angle, setRotateAngle] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [font_size, setFontSize] = useState('');
  const [opacity, setOpacity] = useState('');
  const [watermarkRef, setWatermarkRef] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const downloadProgressRef = useRef(downloadProgress);

  const updateProgressBar = (progress) => {
    //alert(progress)
    const integerProgress = Math.round(progress);
    setDownloadProgress(integerProgress);
    downloadProgressRef.current = integerProgress;  // Update the ref immediately
  };

  const [watermarkProperties, setWatermarkProperties] = useState([
    // Array of watermark properties for each page
    // Example: { left: 100, top: 100, width: 100, height: 100, rotateAngle: 0 }
    // ... add properties for each page ...
  ]);
  // Function to update the progress bar with an integer value

  const calculateTextWidth = (text, fontSize) => {
    const tempDiv = document.createElement("div");
    tempDiv.style.fontSize = `${fontSize}px`;
    tempDiv.style.display = "inline-block";
    tempDiv.innerText = text;
    document.body.appendChild(tempDiv);
    const width = tempDiv.clientWidth;
    document.body.removeChild(tempDiv);

    // check if text empty then return 60


    return width;
  };


  const applyWatermarkToPageText = async (page, watermarkProps, helveticaFont) => {
    const { width, height } = page.getSize();

    // console.log(width, watermarkProps, "watermarkPropswatermarkPropswatermarkPropswatermarkPropswatermarkProps")


    const scaleX = width / watermarkProps.width;
    const scaleY = height / watermarkProps.height;
    const heightPage = watermarkProps.heightPercentage / 100 * height;

    const top = (watermarkProps.topPercentage / 100) * height;

    const left = (((watermarkProps.letPercentage / 100) * width));


    let textX = left - 50;
    let textY = height - top - heightPage + calculateTextHeight(watermarkProps.watermarkText, watermarkProps.fontSize, watermarkProps.scale) / 4 + 18; // Adjust to match resizable watermark

    const scaleVlaue = watermarkProps.fontSize / 100;



    const colorValue = watermarkProps.color.startsWith('#') ? watermarkProps.color.substring(1) : watermarkProps.color;
    const red = parseInt(colorValue.substring(0, 2), 16) / 255;
    const green = parseInt(colorValue.substring(2, 4), 16) / 255;
    const blue = parseInt(colorValue.substring(4, 6), 16) / 255;
    const fontSizeValue = parseFloat(watermarkProps.fontSize);

    const watermarkText = watermarkProps.watermarkText; // Use the provided watermark text

   
    // Apply watermark text to the page with rotation
    page.drawText(watermarkText, {
      x: (left ?? 0),
      y: textY,
      size: (watermarkProps.fontSize / watermarkProps.scale),
      color: rgb(red, green, blue),
      font: helveticaFont,
      opacity: watermarkProps.opacity,
      // Adjust rotation origin
      rotate: degrees(-watermarkProps.rotateAngle),
    });
    


  };

  const applyWatermarkToPageImage = async (page, watermarkProps, imageUrl, pdfDoc) => {

    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();

    // Convert the ArrayBuffer to Uint8Array
    const uint8Array = new Uint8Array(arrayBuffer);

    // Get the image extension from the URL
    const imageExtension = imageUrl.split('.').pop();

    let embeddedImage;
    if (imageExtension === 'png') {
      embeddedImage = await pdfDoc.embedPng(uint8Array);
    } else if (imageExtension === 'jpeg' || imageExtension === 'jpg') {
      embeddedImage = await pdfDoc.embedJpg(uint8Array);
    } else {
      throw new Error('Unsupported image format');
    }
    const { width, height } = page.getSize();
    // console.log(width, height, "watermarkPropswatermarkPropswatermarkPropswatermarkPropswatermarkProps")
    // const { width: imageWidth, height: imageHeight } = embeddedImage.scale(0.5); // Adjust the scale factor as needed

    //   const scaleX = width / width;
    const scaleY = height / height;
    const scaleX = width / width;
    // Calculate the position for the image
    const left = (watermarkProps.letPercentage / 100) * width;
    const widthPage = watermarkProps.width / watermarkProps.scale;
    const heightPage = watermarkProps.height / watermarkProps.scale;
    const top = (watermarkProps.topPercentage / 100) * height
    // console.log(top, height, heightPage, watermarkProps.topPercentage, watermarkProps.scale, "watermarkPropswatermarkPropswatermarkPropswatermarkPropswatermarkProps");



    let imageX = watermarkProps.originalLeft;
    let imageY = height - top - (heightPage) - 10; // Adjust to match resizable watermark
    if (watermarkProps.rotateAngle) {
      // const rotatedPosition = calculateRotatedImagePosition(
      //   watermarkProps.left * scaleX,
      //   height - watermarkProps.top - watermarkProps.height,
      //   watermarkProps.rotateAngle
      // );

      //   imageX = rotatedPosition.x;
      //   imageY = rotatedPosition.y;

      // get the height of the image
      //const imageHeight = watermarkProps.height/2;
      //const imageWidth = watermarkProps.width/2 - 50;

      // imageX += imageWidth;
      //imageY += imageHeight;
    }
    // Add the image as a watermark on the page

    // console.log(watermarkProps.widthPercentage, watermarkProps.heightPercentage, "watermarkPropswatermarkPropswatermarkPropsopacity")


    // console.log(widthPage, heightPage, width, height, "watermarkPropswatermarkPropswatermarkPropsopacity")

    page.drawImage(embeddedImage, {
      x: left + 5,
      y: imageY,
      rotate: degrees(-watermarkProps.rotateAngle),
      width: (watermarkProps.width / watermarkProps.scale), // Replace with the width of the image
      height: (watermarkProps.height / watermarkProps.scale), // Replace with the height of the image
      opacity: watermarkProps.opacity,

    });
  }







  const calculateTextHeight = (text, fontSize, scale) => {
    const tempDiv = document.createElement("div");
    tempDiv.style.fontSize = `${fontSize}px`;
    tempDiv.style.display = "inline-block";
    tempDiv.innerText = text;
    document.body.appendChild(tempDiv);
    const height = tempDiv.clientHeight;
    tempDiv.style.display = "none";


    return height;
    ;
  };



  const updateWatermark = async (watermarkProperties, opacity, font_color, font_size, content, apply_on_all = false) => {
    try {
      setLoading(true);

      if (slugFileData?.base64 && file) {
        const pdfDoc = await PDFDocument.load(file.file);
        const pages = pdfDoc.getPages();
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        // check if apply_on_all is true then duplicate watermark on first page to all pages
        if (apply_on_all && pages.length != watermarkProperties.length) {
          const watermarkProps = watermarkProperties[0];
          for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const page = pages[pageIndex];
            if (!watermarkProps || watermarkProps.length === 0) continue; // Skip if no watermarks for this page
            for (const watermark of watermarkProps) {
              if (watermark.type !== "image" && !watermark.hide) {
                await applyWatermarkToPageText(page, watermark, helveticaFont);
              }
              else if (watermark.type === "image" && !watermark.hide) {
                // console.log(watermark,"&& !watermark.hide&& !watermark.hide&& !watermark.hide")
                await applyWatermarkToPageImage(page, watermark, watermark.image, pdfDoc);
              }
            }
          }
        }


        else {
          for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const page = pages[pageIndex];
            const watermarkProps = watermarkProperties[pageIndex];

            if (!watermarkProps || watermarkProps.length === 0) continue; // Skip if no watermarks for this page



            for (const watermark of watermarkProps) {
              if (watermark.type !== "image" && !watermark.hide) {
                await applyWatermarkToPageText(page, watermark, helveticaFont);
              }
              else if (watermark.type === "image" && !watermark.hide) {
                // console.log(watermark,"&& !watermark.hide&& !watermark.hide&& !watermark.hide")
                await applyWatermarkToPageImage(page, watermark, watermark.image, pdfDoc);
              }


            }
          }
        }

        const pdfBytes = await pdfDoc.save();
        setModifiedPDF(pdfBytes);
      }
    } catch (error) {
      console.error("Error applying watermark:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modifiedPDF) {
      setLoading(false);
    }

  }, [modifiedPDF])





  const calculateRotatedImagePosition = (x, y, angle) => {
    const radians = degreesToRadians(angle);
    const newX = x * Math.cos(radians) + y * Math.sin(radians);
    const newY = -x * Math.sin(radians) + y * Math.cos(radians);
    return { x: newX, y: newY };
  };

  const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;


  const updateWatermark2 = async (watermarkProperties, opacity, url, ex) => {
    setLoading(true);
    if (slugFileData?.base64 && file) {



      // saveFile(existingPdfBytes.data)

      // const existingPdfBytes = await fetch(slugFileData?.base64).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(file.file);


      const pages = pdfDoc.getPages();


      // Load the image and embed it in the PDF
      const imageBytes = atob(url);
      const uint8Array = new Uint8Array(imageBytes.length);
      for (let i = 0; i < imageBytes.length; i++) {
        uint8Array[i] = imageBytes.charCodeAt(i);
      }
      let embeddedImage;
      if (ex === 'png') {
        embeddedImage = await pdfDoc.embedPng(uint8Array);
      } else if (ex === 'jpeg' || ex === 'jpg') {
        embeddedImage = await pdfDoc.embedJpg(uint8Array);
      } else {
        throw new Error('Unsupported image format');
      }

      for (const pageIndex in pages) {
        const page = pages[pageIndex];
        const watermarkProps = watermarkProperties[pageIndex];

        if (!watermarkProps) continue; // Skip if no watermark for this page

        const { width, height } = page.getSize();
        const { width: imageWidth, height: imageHeight } = embeddedImage.scale(0.5); // Adjust the scale factor as needed

        const scaleX = width / width;
        const scaleY = height / height;
        // Calculate the position for the image
        let imageX = watermarkProps.left * scaleX;
        let imageY = height - watermarkProps.top - watermarkProps.height; // Adjust to match resizable watermark
        if (watermarkProps.rotateAngle) {
          // Calculate the rotated image position
          const rotatedPosition = calculateRotatedImagePosition(
            watermarkProps.left * scaleX,
            height - watermarkProps.top - watermarkProps.height,
            watermarkProps.rotateAngle
          );

          // Update image position
          imageX = rotatedPosition.x;
          imageY = rotatedPosition.y;
        }
        // Add the image as a watermark on the page
        page.drawImage(embeddedImage, {
          x: imageX,
          y: imageY,
          rotate: degrees(-watermarkProps.rotateAngle),
          width: watermarkProps.width, // Replace with the width of the image
          height: watermarkProps.height, // Replace with the height of the image
          opacity: opacity, // Adjust the opacity as needed
        });
      }

      const pdfBytes = await pdfDoc.save()


      //const modifiedPDFData = await pdfDoc.saveAsBase64({ dataUri: true });

      setModifiedPDF(pdfBytes);




    }
  };





  const [custom, setCustom] = useState('')
  const [fileurl, setFileUrl] = useState('')
  const convertBase64ToUrl = (base64Data) => {

    setFileUrl(base64Data);
  };

  function getFileExtension(filename) {
    // Split the filename by '.' to separate the name and extension
    const parts = filename.split('.');

    // Check if there is more than one part (i.e., there is an extension)
    if (parts.length > 1) {
      // The extension is the last part of the filename
      return parts.pop().toLowerCase();
    } else {
      // If there's no extension, return an empty string
      return '';
    }
  }

  useEffect(() => {
    if (slugFileData && slugFileData.document_details.file_type != "pdf") {
      //// console.log(slugFileData,"slugFileDataslugFileDataslugFileDataslugFileData");
      convertBase64ToUrl(slugFileData.base64)
      // console.log(slugFileData, "slugFileData.document_details.file_type")
      setCustom(slugFileData.document_details.file_type);
    }
    else if (slugFileData && slugFileData.waterMark) {
      //// console.log(slugFileData.waterMark, "urlurlurlurlurlurlurl")
      const {
        name: watermarkName,
        document_id: watermarkDocumentId,
        type: watermarkType,
        content: watermarkContent,
        width: watermarkWidth,
        height: watermarkHeight,
        left: watermarkLeft,
        top: watermarkTop,
        rotate_angle: watermarkRotateAngle,
        font_color: watermarkFontColor,
        font_size: watermarkFontSize,
        opacity: watermarkOpacity,
        watermarkRef: watermarkReference,
      } = slugFileData.waterMark;

      if (watermarkType == "text") {
        //// console.log(watermarkName, watermarkDocumentId, watermarkType, "font_colorfont_colorfont_colorfont_color")
        setfont_color(watermarkFontColor);
        setName(watermarkName);
        setDocumentId(watermarkDocumentId);
        setType(watermarkType);
        setContent(watermarkContent);
        setWidth(watermarkWidth);
        setHeight(watermarkHeight);
        setLeft(watermarkLeft);
        setTop(watermarkTop);
        setRotateAngle(watermarkRotateAngle);
        setFontColor(watermarkFontColor);
        setFontSize(watermarkFontSize);
        setOpacity(watermarkOpacity);
        setWatermarkRef(watermarkReference);
        const responseWatermarkProperties = JSON.parse(slugFileData.waterMark.watermark_properties);
        setWatermarkProperties(responseWatermarkProperties);

        const apply_on_all = slugFileData.waterMark.applied_all;

        //  // console.log(responseWatermarkProperties,"slugFileData.waterMark.watermarkPropertiesslugFileData.waterMark.watermarkProperties")
        if (!loading && !modifiedPDF && file)
          updateWatermark(responseWatermarkProperties, watermarkOpacity, watermarkFontColor, watermarkFontSize, watermarkContent, apply_on_all);
      }
      else {
        seturl(slugFileData.waterMark.image_data)
        setex(slugFileData.waterMark.extension)
        //// console.log(url, "urlurlurlurlurlurlurl")
        setWidth(watermarkWidth);
        setHeight(watermarkHeight);
        setLeft(watermarkLeft);
        setTop(watermarkTop);
        setRotateAngle(watermarkRotateAngle);
        setFontColor(watermarkFontColor);
        setFontSize(watermarkFontSize);
        setOpacity(watermarkOpacity);
        const responseWatermarkProperties = JSON.parse(slugFileData.waterMark.watermark_properties);
        setWatermarkProperties(responseWatermarkProperties);
        if (!loading && !modifiedPDF && file)
          updateWatermark2(responseWatermarkProperties, watermarkOpacity, slugFileData.waterMark.image_data, slugFileData.waterMark.extension)

      }

    }
    else {
      //  alert('fdf')
      if (!loading && !modifiedPDF && file) {

        addPdf()
      }
    }


  }, [slugFileData, slugFileData.base64, file, url]);

  const addPdf = async () => {
    const pdfDoc = await PDFDocument.load(file.file);
    const pdfBytes = await pdfDoc.save()
    setModifiedPDF(pdfBytes);
  }

  const [fileViewerVisible, setFileViewerVisible] = useState(true);

  const toggleFileViewer = () => {
    setFileViewerVisible(!fileViewerVisible);
  };

  const downloadFile = () => {
    // Create an anchor element and click it to initiate the download
    const anchor = document.createElement('a');
    anchor.href = fileurl;
    anchor.download = slugFileData.document_details.name + '.' + slugFileData.document_details.file_extension; // Set the desired file name for download
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up the anchor element (optional)
    document.body.removeChild(anchor);
  };
  document.title = "GrandTransfer.io | " + slugFileData?.document_details?.name;

  return (
    <>
      {loading ? (
        <>

          <Loader progress={true} />
        </>
      ) :
        <>
          {custom ?
            <div
            >

              {slugFileData.document_details.file_type == "folder" ?
                <FolderView slugFileData={slugFileData} getPdf={getPdf}
                />
                :
                <ViewerWrapper
                  fileUrl={fileurl}
                  custom={custom}
                  fileType={slugFileData.document_details.file_extension.toUpperCase()}
                  fileName={slugFileData.document_details.name}
                  file_extension={slugFileData.document_details.file_extension}
                  preview={preview}
                  selectionModePluginInstance={selectionModePluginInstance}
                  getFilePluginInstance={getFilePluginInstance}
                  allowprint={slugFileData?.allowPrint}
                  allowdwonload={slugFileData?.download}
                  size={slugFileData?.document_details.size}
                  handleDownload={handleDownload}
                  slugFileData={slugFileData}

                />
              }


            </div>

            : (
              <>
                {(modifiedPDF) ? (
                  <>
                    {openPdfByPassword?.passwordAvailable && !openPdfByPassword?.passwordMatch ? (
                      <PasswordDialog />
                    ) :


                      (
                        <ViewerWrapper
                          fileUrl={modifiedPDF}
                          preview={preview}
                          selectionModePluginInstance={selectionModePluginInstance}
                          getFilePluginInstance={getFilePluginInstance}
                          allowprint={slugFileData?.allowPrint}
                          allowdwonload={slugFileData?.download}
                          handleDownload={handleDownload}
                        />
                      )}
                  </>
                ) :
                  <Loader />
                }
              </>
            )}

        </>
      }




    </>

  );
}

export default React.memo(PdfViewer);
