import React, { useState } from "react";
import { Accordion } from "flowbite-react";
import "./FolderTree.css"; // Import CSS file for styling
import down from "./download-minimalistic-svgrepo-com.png";
import folderIcon from "./folder.svg";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FcFolder, FcOpenedFolder } from "react-icons/fc";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const FolderTree = ({ slugFileData, handleDownload }) => {
  const [openFolders, setOpenFolders] = useState([]);

  const toggleFolder = (folderName) => {
    setOpenFolders((prevOpenFolders) =>
      prevOpenFolders.includes(folderName)
        ? prevOpenFolders.filter((name) => name !== folderName)
        : [...prevOpenFolders, folderName]
    );
  };
  function formatBytes(bytes, decimals = 2) {
    bytes = bytes * 1024;
    if (bytes === 0) return "0 Byte";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const formatDate = (timestamp) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    // add Am or Pm

    return new Date(timestamp).toLocaleString("en-US", options);
  };

  const renderFolder = (folder) => (
    <div
      className={` folder ${openFolders.includes(folder.name) ? "opened" : ""}`}
      key={folder.name}
    >
      <div
        className="cursor-pointer  "
        onClick={() => toggleFolder(folder.name)}
      >
        <hr className="my-3"/>
        <span>
          {" "}
          <div>
            <div class="flex justify-between items-center">
              <div>
                <div className="flex align-items-center folder-list ">
                  <div className="">
                    {folder.type === "folder" ? (
                      openFolders.includes(folder.name) ? (
                        <div className="flex">
                          <IoIosArrowDown className="h-5 w-auto font-thin	text-gray folderarrorw" />

                          <FcOpenedFolder className="h-5 w-auto mx-1" />
                        </div>
                      ) : (
                        <div className="flex">
                          <IoIosArrowForward className="h-5 w-auto mt-1 font-thin gray	folderarrorw" />
                          <FcFolder className="h-6 w-auto mx-1" />
                        </div>
                      )
                    ) : null}
                  </div>
                  <div>
                    <span class="text14 text-[#000000]">{folder.name}</span>
                    <span class="block lg:mt-[0.13888888888vw] mt-[2px] text12 text-[#A9A9A9]">
                      ({formatBytes(folder?.size)})
                    </span>
                  </div>
                </div>
              </div>
              <img
                src="/assets/images/white-down.svg"
                onClick={() => {
                  const pathSegments = slugFileData.link.split("/");

                  // Take the first two segments
                  const firstTwoSegments = pathSegments.slice(0, 2);

                  // Join the first two segments and add the desired string
                  const modifiedPath =
                    firstTwoSegments.join("/") + "/" + folder.name;

                  handleDownload(
                    modifiedPath,
                    folder.name,
                    "folder",
                    folder.size,
                    folder.id,
                    folder.subfolders
                  );
                }}
                alt="down"
                class="lg:w-[1.66666666667vw] w-[20px] h-[20px] lg:min-w-[1.66666666667vw] min-w-[20px] lg:h-[1.66666666667vw] cursor-pointer"
              />
            </div>
           
          </div>
        </span>
      </div>
      <div
        className={`${
          openFolders.includes(folder.name) ? "slide-in" : "slide-out"
        } 
      `}
      >
        {folder.type === "folder" &&
          openFolders.includes(folder.name) &&
          folder.subfolders &&
          folder.subfolders.map((child) =>
            child.type === "folder" ? (
              renderFolder(child)
            ) : (
              <div className="file container" key={child.name}>
                <hr class="my-3" />
                <span>
                  {" "}
                  <div>
                    <div class="flex justify-between items-center">
                      <div>
                        <div className="flex align-items-center">
                          <div className="customIconSize2 mx-2 ">
                            <FileIcon
                              extension={"pdf"}
                              {...defaultStyles["pdf"]}
                            />
                          </div>
                          <h6 class="font-semibold">{folder.name}</h6>
                        </div>
                        <h6 class="text-sm mt-1 text-neutral-500 font-semibold mx-8">
                          424245
                        </h6>
                      </div>
                      <img
                        src={down}
                        className="h-7 w-auto mx-2 mt-1  cursor-pointer"
                      />
                    </div>
                    <hr class="my-4" />
                  </div>
                </span>
              </div>
            )
          )}
        <div className=" ">
          {folder.type === "folder" &&
            openFolders.includes(folder.name) &&
            folder.documents && (
              <div className="">
                {folder.documents.map((folder) =>
                  folder.type === "folder" ? (
                    renderFolder(folder)
                  ) : (
                    <div className="file" key={folder.name}>
                      <hr class="my-3" />
                      <span>
                        {" "}
                        <div class="flex justify-between items-center mt-1 ">
                          <div>
                            <div className="flex align-items-center">
                              <div className="customIconSize2 mx-2 ">
                                <FileIcon
                                  extension={folder.file_extension}
                                  {...defaultStyles[folder.file_extension]}
                                />
                              </div>
                              <div class="flex w-full flex-col ">
                                <span
                                  data-file-name={
                                    folder.name + "." + folder.file_extension
                                  }
                                  class="text14 fileNameSpan text-[#000000]"
                                  onClick={() =>
                                    handleDownload(
                                      folder.pdf_url,
                                      folder.name + "." + folder.file_extension,
                                      "file",
                                      folder.size,
                                      folder.id
                                    )
                                  }
                                >
                                  {folder.name.length > 20
                                    ? folder.name.substring(0, 20) + "..."
                                    : folder.name}
                                  {" ." + folder.file_extension}
                                </span>
                                <span class="block lg:mt-[0.34722222222vw] mt-[4px] lg:text-[0.76388888888vw] text12 text-[#A9A9A9]">
                                  {formatBytes(folder?.size)} -{" "}
                                  {formatDate(folder?.created_at)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <img
                            src="/assets/images/white-down.svg"
                            onClick={() =>
                              handleDownload(
                                folder.pdf_url,
                                folder.name + "." + folder.file_extension,
                                "file",
                                folder.size,
                                folder.id
                              )
                            }
                            alt="down"
                            class="lg:w-[1.66666666667vw] w-[20px] h-[20px] lg:min-w-[1.66666666667vw] min-w-[20px] lg:h-[1.66666666667vw] cursor-pointer"
                          />
                        </div>
                      </span>
                    </div>
                  )
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="">
      {slugFileData.document_details.subfolders && (
        <>
          {slugFileData.document_details.subfolders.map((folder) =>
            renderFolder(folder)
          )}
        </>
      )}
      {slugFileData.document_details.documents && (
        <>
          {slugFileData.document_details.files.map((folder) => (
            <div className="">
              <hr class="my-3" />
              <span>
                {" "}
                <div class="flex justify-between items-center">
                  <div>
                    <div className="flex align-items-center">
                      <div className="customIconSize2 mx-2 ">
                        <FileIcon
                          extension={folder.file_extension}
                          {...defaultStyles[folder.file_extension]}
                        />
                      </div>
                      <div class="flex w-full flex-col">
                        <span
                          data-file-name={
                            folder.name + "." + folder.file_extension
                          }
                          class="text14 fileNameSpan text-[#000000] "
                          onClick={() =>
                                    handleDownload(
                                      folder.pdf_url,
                                      folder.name + "." + folder.file_extension,
                                      "file",
                                      folder.size,
                                      folder.id
                                    )
                                  }
                        >
                          {folder.name.length > 20
                            ? folder.name.substring(0, 20) + "..."
                            : folder.name}
                          {" ." + folder.file_extension}
                        </span>

                        <span class="block lg:mt-[0.34722222222vw] mt-[4px] lg:text-[0.76388888888vw] text12 text-[#A9A9A9]">
                          {formatBytes(folder?.size)} -{" "}
                          {formatDate(folder?.created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <img
                    src="/assets/images/white-down.svg"
                    onClick={() =>
                      handleDownload(
                        folder.pdf_url,
                        folder.name + "." + folder.file_extension,
                        "file",
                        folder.size,
                        folder.id
                      )
                    }
                    alt="down"
                    class="lg:w-[1.66666666667vw] w-[20px] h-[20px] lg:min-w-[1.66666666667vw] min-w-[20px] lg:h-[1.66666666667vw] cursor-pointer"
                  />
                </div>
              </span>
            </div>
          ))}
          <hr class="mt-3" />
        </>
      )}
    </div>
  );
};

export default FolderTree;
