import React, { useEffect, useState, useRef } from "react";
import ViewerWrapper from "./components/ViewerWrapper";
import _, { get, set } from "lodash";
import useWaterMarkHook from "./components/WaterMark.hook";
import { PrimaryButton, TextBox, Viewer } from "@react-pdf-viewer/core";
import { MobileView, isMobile } from "react-device-detect";
import { useParams } from "react-router";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/core/lib/styles/index.css";
import LandingPage from "./components/LandingPage";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { PDFDocument, StandardFonts, Color, rgb, degrees } from "pdf-lib";

import Loader from "../../components/Loader";
import { FiDownload } from "react-icons/fi";
import {
  SelectionMode,
  selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";
import { ProxyUrl, S3BaseUrl } from "../../service/Api/BaseUrl.constants";
import axios from "axios";
import PdfViewer from "./components/PdfViewer";
import PasswordDialog from "./components/PasswordDialog";
import DownloadLoader from "./components/DowloadLoader";
import DetectPhotoComponent from "./components/DetectPhotoComponent";

let isMouseOnDocument = false;
const Home = ({ geoLocationData, navigate, showoverlays }) => {
  const { slug } = useParams();
  useEffect(() => {
    const { pathname } = window.location;
    const parts = pathname.split('/');

    // Check if there is no slug in the URL
    if (!slug) {
      // Redirect to Google
      window.location.replace('https://grandtransfer.io/');
    }
  }, []);



  const {
    preview,
    getPdf,
    renderPage,
    ARRAY_VALUE,
    notAvailableInCountry,
    openPdfByPassword,
    setOpenPdfByPassword,
    downloadAccess,
    setOpenPdfByface,
    openPdfByface,
    displayView,
    handleDownloadPdf,
    isLoading,
    slugFileData,
    slugInvalidError,
  } = useWaterMarkHook({ slug, geoLocationData });

  const [error, setError] = React.useState(false);
  const [showOverlay, setShowOverlay] = useState(true);


  // handling screenshot, overlay and protection logic  
  useEffect(() => {
    // hadi cleaned up and optomized below
    let isKeyPressed = false;
    let timeoutId = null;
    const documentBody = document.querySelector("body");
    document.addEventListener('keyup', () => { isKeyPressed = false; });

    const handleKeydown = (e) => {

      // handling screenshot
      if (slugFileData?.screenshot == "false") {


        if ((e.metaKey || e.altKey || e.ctrlKey) && (e.shiftKey || e.key.toLowerCase() === "shift" || e.key.toLowerCase() === "tab")) {
          var elementr = document.getElementById("root");
          elementr.classList.add("screen-disable");
          var element = document.getElementById("restrictedmsg");
          element && element.classList.remove("hidden");
          e.preventDefault();
          return false;
        }

        if (e.key == "PrintScreen" || e.key.toLowerCase() == "printscreen") {
          var elementr = document.getElementById("root");
          elementr.classList.add("screen-disable");
          var element = document.getElementById("restrictedmsg");
          element && element.classList.remove("hidden");
          e.preventDefault();
          return false;
        }



        window.addEventListener('print', () => {
          e.preventDefault();
          return false;
        });

      }
      if (slugFileData?.screenshot == "false" || slugFileData?.allowPrint == "false") {



        if ((e.metaKey || e.altKey || e.ctrlKey) && (slugFileData?.copy == "false")) {

          isKeyPressed = true;
          setShowOverlay(true);

          e.preventDefault();
          return false;
        }

        if ((e.metaKey || e.altKey || e.ctrlKey) && (e.key.toLowerCase() === 'p' || e.key === "PrintScreen" || e.key.toLowerCase() === "printscreen")) {
          e.preventDefault();
          return false;
        }


      }

    }
    // end handleKeydownevents



    // const handleMouseEnter = () => { setShowOverlay(false); isKeyPressed = false; };
    // const handleMouseMove = () => {

    //   if (isKeyPressed) {
    //     return;
    //   }

    //   setShowOverlay(false);

    //   clearTimeout(timeoutId);
    //   timeoutId = setTimeout(() => {
    //     setShowOverlay(true);
    //   }, 10000); // 10 seconds
    // };

    const handleMouseLeave = () => { setShowOverlay(true); isKeyPressed = false; isMouseOnDocument = false; };
    const onBlur = () => { isKeyPressed = false; setShowOverlay(true); };
    const handleMouseClick = () => {

      if (isKeyPressed) {
        return;
      }
      isMouseOnDocument = true;
      setShowOverlay(false);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowOverlay(true);
      }, 50000); // 10 seconds
    };


    const visibilityChangeHandler = () => {
      if (document.hidden || document.visibilityState === "hidden") {
        setShowOverlay(true);
      }
      isKeyPressed = false;
    };


    // documentBody.addEventListener("mouseenter", handleMouseEnter);
    // documentBody.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keydown", handleKeydown);
    documentBody.addEventListener("click", handleMouseClick);
    documentBody.addEventListener("mouseleave", handleMouseLeave);
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    window.addEventListener('blur', onBlur);

    return () => {
      // documentBody.removeEventListener("mouseenter", handleMouseEnter);
      // documentBody.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keydown", handleKeydown);
      documentBody.removeEventListener("click", handleMouseClick);
      documentBody.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
      window.removeEventListener('blur', onBlur);

      clearTimeout(timeoutId);
    };


  }, [slugFileData]);

  // end handling screenshot, overlay and protection logic


  // handling file fetch and watermark work
  const getFilePluginInstance = getFilePlugin();
  const { Download, DownloadIcon } = getFilePluginInstance;

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [download, setDownload] = useState(false);
  document.title = slugFileData
    ? "GrandTransfer.io | " + slugFileData?.document_details?.name
    : "GrandTransfer.io | Viewer";
  const [modifiedPDF, setModifiedPDF] = useState(null);
  const [pdfW, setPdfw] = useState(500);
  const [pdfh, setPdfh] = useState(842);
  const [url, seturl] = useState(null);
  const [ex, setex] = useState(null);
  const [font_color, setfont_color] = useState("");
  const [name, setName] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [type, setType] = useState("");
  const [content, setContent] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [left, setLeft] = useState("");
  const [top, setTop] = useState("");
  const [rotate_angle, setRotateAngle] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [font_size, setFontSize] = useState("");
  const [opacity, setOpacity] = useState("");
  const [watermarkRef, setWatermarkRef] = useState("");
  const [file, setFile] = useState(null);
  const saveFile = async (data) => {

    const fileData = {
      file: data,
    }
    setFile(fileData);

  }

  const [watermarkProperties, setWatermarkProperties] = useState([
    // Array of watermark properties for each page
    // Example: { left: 100, top: 100, width: 100, height: 100, rotateAngle: 0 }
    // ... add properties for each page ...
  ]);

  const calculateRotatedImagePosition = (x, y, angle) => {
    const radians = degreesToRadians(angle);
    const newX = x * Math.cos(radians) + y * Math.sin(radians);
    const newY = -x * Math.sin(radians) + y * Math.cos(radians);
    return { x: newX, y: newY };
  };

  const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

  // Function to download a blob with a given filename
  const downloadBlob = (blob, fileName) => {
    //getPdf(ARRAY_VALUE);
    const link = document.createElement('a');


    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const applyWatermarkToPageText = async (page, watermarkProps, helveticaFont) => {
    const { width, height } = page.getSize();


    const scaleX = width / watermarkProps.width;
    const scaleY = height / watermarkProps.height;
    const heightPage = watermarkProps.heightPercentage / 100 * height;

    // console.log(width, height, "watermarkPropswatermarkPropswatermarkPropswatermarkPropswatermarkProps")
    const top = (watermarkProps.topPercentage / 100) * height;

    const left = (((watermarkProps.letPercentage / 100) * width));

    // console.log(calculateTextHeight(watermarkProps.watermarkText, watermarkProps.fontSize, watermarkProps.scale), heightPage, "11watermarkPropswatermarkPropswatermarkPropswatermarkPropswatermarkProps")

    let textX = left - 50;
    let textY = height - top - heightPage + calculateTextHeight(watermarkProps.watermarkText, watermarkProps.fontSize, watermarkProps.scale) / 4 + 18; // Adjust to match resizable watermark

    const scaleVlaue = watermarkProps.fontSize / 100;



    const colorValue = watermarkProps.color.startsWith('#') ? watermarkProps.color.substring(1) : watermarkProps.color;
    const red = parseInt(colorValue.substring(0, 2), 16) / 255;
    const green = parseInt(colorValue.substring(2, 4), 16) / 255;
    const blue = parseInt(colorValue.substring(4, 6), 16) / 255;
    const fontSizeValue = parseFloat(watermarkProps.fontSize);

    const watermarkText = watermarkProps.watermarkText; // Use the provided watermark text

    if (watermarkProps.rotateAngle) {
      //  textY += calculateTextWidth(watermarkProps.watermarkText, watermarkProps.fontSize) / 2 - 90;
    }

    // Apply watermark text to the page with rotation
    page.drawText(watermarkText, {
      x: (left ?? 0),
      y: textY,
      size: (watermarkProps.fontSize / watermarkProps.scale),
      color: rgb(red, green, blue),
      font: helveticaFont,
      opacity: watermarkProps.opacity,
      // Adjust rotation origin
      rotate: degrees(-watermarkProps.rotateAngle),
    });

  };

  const applyWatermarkToPageImage = async (page, watermarkProps, imageUrl, pdfDoc) => {
    // alert('okkkkk')
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();


    // Convert the ArrayBuffer to Uint8Array
    const uint8Array = new Uint8Array(arrayBuffer);

    // Get the image extension from the URL
    const imageExtension = imageUrl.split('.').pop();

    let embeddedImage;
    if (imageExtension === 'png') {
      embeddedImage = await pdfDoc.embedPng(uint8Array);
    } else if (imageExtension === 'jpeg' || imageExtension === 'jpg') {
      embeddedImage = await pdfDoc.embedJpg(uint8Array);
    } else {
      throw new Error('Unsupported image format');
    }
    const { width, height } = page.getSize();
    // console.log(width, height, "watermarkPropswatermarkPropswatermarkPropswatermarkPropswatermarkProps")
    // const { width: imageWidth, height: imageHeight } = embeddedImage.scale(0.5); // Adjust the scale factor as needed

    //   const scaleX = width / width;
    const scaleY = height / height;
    const scaleX = width / width;
    // Calculate the position for the image
    const left = (watermarkProps.letPercentage / 100) * width;
    const widthPage = watermarkProps.width / watermarkProps.scale;
    const heightPage = watermarkProps.height / watermarkProps.scale;
    const top = (watermarkProps.topPercentage / 100) * height
    // console.log(top, height, heightPage, watermarkProps.topPercentage, watermarkProps.scale, "watermarkPropswatermarkPropswatermarkPropswatermarkPropswatermarkProps");



    let imageX = watermarkProps.originalLeft;
    let imageY = height - top - (heightPage) - 10; // Adjust to match resizable watermark
    if (watermarkProps.rotateAngle) {
      // const rotatedPosition = calculateRotatedImagePosition(
      //   watermarkProps.left * scaleX,
      //   height - watermarkProps.top - watermarkProps.height,
      //   watermarkProps.rotateAngle
      // );

      //   imageX = rotatedPosition.x;
      //   imageY = rotatedPosition.y;

      // get the height of the image
      //const imageHeight = watermarkProps.height/2;
      //const imageWidth = watermarkProps.width/2 - 50;

      // imageX += imageWidth;
      //imageY += imageHeight;
    }
    // Add the image as a watermark on the page

    // console.log(watermarkProps.widthPercentage, watermarkProps.heightPercentage, "watermarkPropswatermarkPropswatermarkPropsopacity")


    // console.log(widthPage, heightPage, width, height, "watermarkPropswatermarkPropswatermarkPropsopacity")

    page.drawImage(embeddedImage, {
      x: left + 5,
      y: imageY,
      rotate: degrees(-watermarkProps.rotateAngle),
      width: (watermarkProps.width / watermarkProps.scale), // Replace with the width of the image
      height: (watermarkProps.height / watermarkProps.scale), // Replace with the height of the image
      opacity: watermarkProps.opacity,

    });
  }




  const calculateTextHeight = (text, fontSize, scale) => {
    const tempDiv = document.createElement("div");
    tempDiv.style.fontSize = `${fontSize}px`;
    tempDiv.style.display = "inline-block";
    tempDiv.innerText = text;
    document.body.appendChild(tempDiv);
    const height = tempDiv.clientHeight;



    return height;
    ;
  };

  const calculateTextWidth = (text, fontSize) => {
    const tempDiv = document.createElement("div");
    tempDiv.style.fontSize = `${fontSize}px`;
    tempDiv.style.display = "inline-block";
    tempDiv.innerText = text;
    document.body.appendChild(tempDiv);
    const width = tempDiv.clientWidth;
    document.body.removeChild(tempDiv);

    // check if text empty then return 60
    if (fontSize <= 50) {
      return width + 130;
    }

    return width;
  };

  const updateWatermark = async (watermarkProperties, font_color, opacity, font_size, content, apply_on_all = false) => {
    try {
      // alert('okkkkkk')

      if (slugFileData?.base64 && file) {

        const pdfDoc = await PDFDocument.load(file.file);

        const pages = pdfDoc.getPages();
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        if (apply_on_all) {
          const watermarkProps = watermarkProperties[0];
          for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const page = pages[pageIndex];
            if (!watermarkProps || watermarkProps.length === 0) continue; // Skip if no watermarks for this page
            for (const watermark of watermarkProps) {
              if (watermark.type !== "image") {
                await applyWatermarkToPageText(page, watermark, helveticaFont);
              }
              else if (watermark.type === "image") {
                await applyWatermarkToPageImage(page, watermark, watermark.image, pdfDoc);
              }
            }
          }
        }
        else {

          for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const page = pages[pageIndex];
            const watermarkProps = watermarkProperties[pageIndex];

            if (!watermarkProps || watermarkProps.length === 0) continue; // Skip if no watermarks for this page



            for (const watermark of watermarkProps) {
              if (watermark.type !== "image") {
                await applyWatermarkToPageText(page, watermark, helveticaFont);
              }
              else if (watermark.type === "image") {
                // console.log(watermark)
                await applyWatermarkToPageImage(page, watermark, watermark.image, pdfDoc);
              }


            }
          }
        }
        const pdfBytes = await pdfDoc.save();

        // Convert pdfBytes directly to Blob
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        setFile(blob);

        const fileName = `${slugFileData?.document_details.name}.${slugFileData?.document_details.file_extension}`;
        downloadBlob(blob, fileName);

        setWait(false);
        setDownloadLoading(false);
        setDownload(false);

        return;
      }
    } catch (error) {
      console.error("Error applying watermark:", error);
    }
  };






  const [wait, setWait] = useState(false);
  const [DownloadUrl, setDownloadUrl] = useState(null)

  const [downloadProgress, setDownloadProgress] = useState(0);
  const downloadProgressRef = useRef(downloadProgress);

  const updateProgressBar = (progress) => {
    const integerProgress = Math.round(progress);
    setDownloadProgress(integerProgress);
    downloadProgressRef.current = integerProgress;  // Update the ref immediately
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (downloadLoading) {
        event.preventDefault();
        event.returnValue = ""; // This triggers the confirmation dialog
      }
    };

    // Attach the event listener if download is in progress
    if (downloadLoading) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [downloadLoading]); // Re-run effect if downloadLoading changes

  
  const handleDownload = (props) => {
    // // console.log(file,"fileeeee")
    setDownloadLoading(true);
    setDownload(true)
    if (file && slugFileData?.document_details.file_extension !== "pdf") {
      downloadBlob(file, `${slugFileData?.document_details.name}.${slugFileData?.document_details.file_extension}`);
      getPdf(ARRAY_VALUE, true);
      setDownloadLoading(false);
      setDownload(false)
      return;
    }
    const url = 'https://api.grandtransfer.io/api/get_pre_sign_url';
    axios.get(url, {
      params: {
        link: slugFileData?.link
      }
    })
      .then(response => {
        // handle success
        // // console.log(response.data);
        const url = (atob(response?.data.url));
        setDownloadUrl(url);
        handleDownload2(props, url);

      })
      .catch(error => {
        // handle error
        // console.log(error);
      });


  }

  const [loading, setLoading] = useState(false)

  const fetchWithoutWaterMark = async () => {
    try {

      setLoading(true);
      const existingPdfBytes = await axios.get(slugFileData?.base64, {
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / (slugFileData.document_details.size * 1024)) * 100;

          const cappedProgress = Math.min(parseInt(progress, 10), 99);
          updateProgressBar(cappedProgress);
        },
      });

      saveFile(existingPdfBytes.data)
      setLoading(false);
      const pdfBytes = new Uint8Array(existingPdfBytes.data);
      // setModifiedPDF(pdfBytes);
    } catch (error) {
      console.error("Error applying watermark:", error);
    }
  };

  useEffect(() => {
    if (slugFileData && !loading && (slugFileData?.document_details.file_extension == "pdf") && (


      (openPdfByface?.faceAvailable && openPdfByface?.faceMatch) ||
      slugFileData?.take_photo != 'true') && (
        (openPdfByPassword?.passwordAvailable &&
          openPdfByPassword?.passwordMatch) ||
        (!(slugFileData?.password !== '' && slugFileData?.password !== undefined && slugFileData?.password !== null))
      ))
      fetchWithoutWaterMark()
  }, [slugFileData, openPdfByface, openPdfByPassword])

  const handleDownload2 = async (props, url) => {

    setDownload(true)
    const DownloadUrl = url;

    const fileName =
      slugFileData?.document_details.name +
      "." +
      slugFileData?.document_details.file_extension;


    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const pdfsExtensions = ['pdf']; // Add any other audio extensions you want to support
    const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'mkv', 'flv', 'wmv', 'mpeg', 'zip', 'rar'];


    if (slugFileData && !(pdfsExtensions.includes(slugFileData?.document_details.file_extension))) {
      const base64Data = slugFileData?.base64;
      setDownloadLoading(true);

      const fileName =
        slugFileData?.document_details.name +
        "." +
        slugFileData?.document_details.file_extension;

      // Axios configuration object with onDownloadProgress
      const axiosConfig = {
        responseType: "blob",
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Origin: "https://viewer.grandtransfer.io",
          Referer: "https://viewer.grandtransfer.io/",
        },
        onDownloadProgress: progressEvent => {
          // Calculate download progress percentage
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setDownloadProgress(progress)

          // You can update a progress bar or perform other actions based on this progress value
        }
      };

      axios
        .get(DownloadUrl, axiosConfig)
        .then((response) => {

          setFile(new Blob([response.data]));
          downloadBlob(new Blob([response.data]), fileName);
          getPdf(ARRAY_VALUE, false);
          setDownloadLoading(false);
          setDownload(false)
          return
        })
        .catch((error) => {
          // console.log(error);
          setDownloadLoading(false);
          setDownload(false);
        });

      return;
    }


    if (slugFileData && (slugFileData?.document_details.file_extension == "pdf")) {

      if (slugFileData?.download_type == "Original") {

        if (file) {

          setDownloadLoading(false);
          setDownload(false)
          const blob = new Blob([file.file], { type: 'application/octet-stream' });
          getPdf(ARRAY_VALUE, true);
          downloadBlob(blob, `${slugFileData?.document_details.name}.${slugFileData?.document_details.file_extension}`);

          return;
        }

      }
      else {
        const {
          name: watermarkName,
          document_id: watermarkDocumentId,
          type: watermarkType,
          content: watermarkContent,
          width: watermarkWidth,
          height: watermarkHeight,
          left: watermarkLeft,
          top: watermarkTop,
          rotate_angle: watermarkRotateAngle,
          font_color: watermarkFontColor,
          font_size: watermarkFontSize,
          opacity: watermarkOpacity,
          watermarkRef: watermarkReference,
        } = slugFileData.downMark;
        //// console.log(slugFileData,"slugFileData?.waterMark.type");
        if (slugFileData?.downMark.type == "text") {
          //// console.log(slugFileData.downMark ,"slugFileData.downMarkslugFileData.downMarkslugFileData.downMark")

          //// console.log(watermarkName , "font_colorfont_colorfont_colorfont_color")
          setfont_color(slugFileData.downMark.font_color);
          setName(slugFileData.downMark.watermarkName);
          setDocumentId(slugFileData.downMark.document_id);
          setType(slugFileData.downMark.type);
          setContent(slugFileData.downMark.content);
          setWidth(slugFileData.downMark.width);
          setHeight(slugFileData.downMark.height);
          setLeft(slugFileData.downMark.left);
          setTop(slugFileData.downMark.top);
          setRotateAngle(slugFileData.downMark.rotate_angle);
          setFontColor(slugFileData.downMark.font_color);
          setFontSize(slugFileData.downMark.font_size);
          setOpacity(slugFileData.downMark.opacity);
          setWatermarkRef(slugFileData.downMark.watermarkRef);
          const responseWatermarkProperties = JSON.parse(
            slugFileData.downMark.watermark_properties
          );
          setWatermarkProperties(responseWatermarkProperties);
          setWait(true);
          const apply_on_all = slugFileData.downMark.applied_all;
          updateWatermark(responseWatermarkProperties, slugFileData.downMark.font_color, slugFileData.downMark.opacity, slugFileData.downMark.font_size, slugFileData.downMark.content, apply_on_all);
          //// console.log(slugFileData.downMark.font_color,"font_colorfont_colorfont_colorfont_color")
        } else {

          seturl(slugFileData.downMark.image_data);
          setex(slugFileData.downMark.extension);
          setWidth(watermarkWidth);
          setHeight(watermarkHeight);
          setLeft(watermarkLeft);
          setTop(watermarkTop);
          setRotateAngle(watermarkRotateAngle);
          setFontColor(watermarkFontColor);
          setFontSize(watermarkFontSize);
          setOpacity(watermarkOpacity);
          setWait(true);
          const responseWatermarkProperties = JSON.parse(
            slugFileData.downMark.watermark_properties
          );
          setWatermarkProperties(responseWatermarkProperties);
          //  updateWatermark2(responseWatermarkProperties, watermarkOpacity, slugFileData.downMark.image_data, slugFileData.downMark.extension);
        }

        return;
      }
    }
    // if (slugFileData.show_with_water_mark !== "true") {
    //   getPdf(ARRAY_VALUE);
    // } else {
    //   getPdf(ARRAY_VALUE);
    // }
  };
  // Function to create a new MutationObserver

  const observeChanges = () => {
    // Select the target node (the body of the document)
    const targetNode = document.body;

    // Create a new observer instance
    const observer = new MutationObserver((mutationsList, observer) => {
      // Check each mutation
      for (const mutation of mutationsList) {
        // Check if nodes are removed
        if (mutation.type === 'childList' && mutation.removedNodes.length > 0 && !isMouseOnDocument) {
          // Check if any removed node has class "black-overlay"
          for (const node of mutation.removedNodes) {
            if (node.classList && node.classList.contains('black-overlay') || node.classList && node.classList.contains('display-screenshot-disable')) {
              setIsrestricted(true);
              return;
            }

          }
        } else if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          // Specifically check for the 'black-overlay' div
          const element = mutation.target;
          if (element.classList.contains('black-overlay')) {
            const style = window.getComputedStyle(element);
            // Now, check if the style properties match the expected values
            if (!isMouseOnDocument && (style.visibility !== 'visible' || style.zIndex !== '10' || parseFloat(style.opacity) < 1 || style.width !== '100%' || style.height !== '100%')) {
              // setIsrestricted(true);
              // return;
            }
          }
        }
      }
    });

    // Configure the observer to watch for changes in child nodes
    // const config = { childList: true, subtree: true };
    const config = { attributes: true, childList: true, subtree: true, attributeFilter: ['style'] };

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  };

  // Call the function to start observing changes
  observeChanges();

  const [timer, setTimer] = useState(0);

  const handleTimerTransition = (timer) => {
    // Set isLoading to the current timer value
    setTimer(timer);

    // Schedule the next transition if the timer is less than 100
    if (timer < 100) {
      setTimeout(() => handleTimerTransition(timer + 1), 20); // Adjust the interval as needed
    } else {
      // If the timer reaches 100, set isLoading to false after 1 second (simulating completion)
      setTimeout(() => setTimer(100), 1000);
    }
  };
  useEffect(() => {
    // console.log(timer)
  }, [timer])

  useEffect(() => {
    handleTimerTransition(0);
  }, []);

  const selectionModePluginInstance = selectionModePlugin({
    selectionMode: slugFileData?.copy == "true" ? "Text" : "Hand",
  });
  const [isrestricted, setIsrestricted] = useState(false);
  const [countdown, setCountdown] = useState(5); // Countdown timer for page refresh

  useEffect(() => {
    let intervalId;

    if (isrestricted) {
      intervalId = setTimeout(() => {
        setCountdown(prevCountdown => {
          const newCountdown = prevCountdown - 1;
          if (newCountdown === 0) {
            window.location.reload();
          }
          return newCountdown;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [countdown, isrestricted]);

// console.log(slugInvalidError,"slugFileDataslugFileDataslugFileDataslugFileDataslugFileData")
  // // Function to decrement countdown and refresh page after 3 seconds
  // useEffect(() => {
  //   if (isrestricted) {
  //     const intervalId = setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);

  //     // Clear interval and refresh page after countdown reaches 0
  //     if (countdown === 0) {
  //       clearInterval(intervalId);
  //       window.location.reload();
  //     }
  //   }
  // }, [countdown, isrestricted]);


  useEffect(() => {
    if (wait) {
      //// console.log(font_color,"font_colorfont_colorfont_colorfont_color")
      ////// console.log(content,"xcxvdsfdcvfsdfdsfdsfdsfsdfsdfds")
      if (type == "text") {
        //updateWatermark();
      } else {
        // updateWatermark2();
      }
    }
  }, [wait]);
  // end handling file fetch and watermark work



  if (displayView == "Mobile" && !isMobile) {
    return <LandingPage msg=" Not available for desktop" />;
  }
  if (displayView == "Web" && isMobile) {
    return <LandingPage msg=" Not available for mobile" />;
  }





  return (
    <>

      {(!slugFileData && !slugInvalidError) ? <div>
        <Loader />
      </div>
        :
        <>
          {isrestricted &&
            <>
              <div
                className="black-overlay"
                style={{
                  opacity: 1,
                  visibility: "visible",
                }}
                onAnimationEnd={() => {
                  if (!showOverlay) setShowOverlay(false);
                }}
              >
                <div className="p-4 mb-4 text-992861 border border-992861 bg-zinc-50 rounded-lg bg-992861-50 dark:bg-gray-800 dark:text-992861 dark:border-992861-800">
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <h3 className="text-lg font-medium">Warning</h3>
                  </div>
                  <div className="mt-2 mb-4 text-sm">
                    A suspicious action has been detected and is not permitted. Please reload the page to resume normal viewing. The page will automatically refresh in {countdown} seconds
                  </div>
                  <div className="flex">
                    {/* <button type="button" className="text-white bg-992861 hover:bg-992861-dark focus:ring-4 focus:outline-none focus:ring-992861 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-992861-dark dark:text-gray-800 dark:hover:bg-992861-dark dark:focus:ring-992861">
                <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                  <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                </svg>
                View more
              </button> */}
                  </div>
                </div>
              </div>
            </>
          }
          {
            slug &&
            <>
              {/* {download && <DownloadLoader file={false} />} */}

              <div
                className={`display-screenshot-disable hidden ${slugFileData?.allowPrint == "true" ? "allow_print" : "block_print"
                  }`}
                id="restrictedmsg"
              >
                <p className="d-flex z-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-dash-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                  </svg>{" "}
                  screen-shot-disable
                </p>
                <button
                  onClick={() => {
                    var elementr = document.getElementById("root");
                    elementr.classList.remove("screen-disable");
                    var element = document.getElementById("restrictedmsg");
                    element.classList.add("hidden");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                  close
                </button>
              </div>

              {(loading && (slugFileData?.document_details.file_extension == "pdf")) ? (
                <Loader downloadProgress={downloadProgress} />
              ) : (
                <>
                  {downloadLoading && <DownloadLoader downloadProgress={downloadProgress} />}

                  {(isLoading || timer < 100 && (slugFileData?.document_details.file_extension !== "pdf")) ?
                    <Loader downloadProgress={timer} />
                    :
                    <>
                      {slug && !slugInvalidError ? (
                        openPdfByPassword?.passwordAvailable &&
                          !openPdfByPassword?.passwordMatch ? (
                          <div
                          >
                            <PasswordDialog
                              slugFileData={slugFileData}
                              error={error}
                              setError={setError}
                              setOpenPdfByPassword={setOpenPdfByPassword}
                            />
                          </div>
                        ) :

                          (
                            <>
                              {(slug && slugFileData && openPdfByface?.faceAvailable && !openPdfByface?.faceMatch) ?
                                <DetectPhotoComponent
                                  slugFileData={slugFileData}
                                  error={error}
                                  setError={setError}
                                  setOpenPdfByface={setOpenPdfByface}
                                />
                                :
                                <div
                                  className={`${slugFileData?.allowPrint == "true"
                                    ? "allow_print"
                                    : "block_print"
                                    } min-h-screen flex items-center justify-center applay_overlays ${slugFileData?.show_black_overlays != "true1" &&
                                      notAvailableInCountry
                                      ? "overlays"
                                      : ""
                                    }`}
                                >
                                  <div className="w-full h-full">
                                    <div className="w-full h-full">
                                      <div className="w-full h-full">
                                        {(() => {
                                          if (
                                            slugFileData?.geo_locations?.includes(
                                              geoLocationData?.countryName
                                            ) ||
                                            slugFileData?.selectCountries === "true"
                                          ) {
                                            return (
                                              <PdfViewer
                                                openPdfByPassword={openPdfByPassword}
                                                saveFile={saveFile}
                                                file={file}
                                                loading={loading}
                                                setLoading={setLoading}
                                                downloadProgress={downloadProgress}
                                                slugFileData={slugFileData}
                                                preview={preview}
                                                getPdf={getPdf}
                                                selectionModePluginInstance={
                                                  selectionModePluginInstance
                                                }
                                                getFilePluginInstance={getFilePluginInstance}
                                                handleDownload={handleDownload}
                                              // PasswordDialog={PasswordDialog}
                                              />
                                            );
                                          } else {
                                            return (
                                              slugFileData &&
                                              notAvailableInCountry == false && (
                                                <LandingPage msg="Sender did not make the shared link available for your country" />
                                              )
                                            );
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                  {(!downloadLoading && slugFileData?.screenshot === "false" &&
                                    notAvailableInCountry
                                    && !((displayView == "Mobile" && !isMobile) || (displayView == "Web" && isMobile))
                                  ) && (
                                      <div
                                        className="black-overlay"
                                        style={{
                                          opacity: showOverlay ? 1 : 0,
                                          visibility: showOverlay ? "visible" : "hidden",
                                          zIndex: showOverlay ? 10 : -1,
                                        }}
                                        onAnimationEnd={() => {
                                          if (!showOverlay) setShowOverlay(false);
                                        }}
                                      >
                                        <div
                                          className="not-active-page-hover-message"
                                          id="hover_text"
                                        >
                                          ON STANDBY <br /> CLICK TO RESUME
                                        </div>
                                      </div>
                                    )}
                                </div>
                              }
                            </>

                          )

                      ) : slugInvalidError !== undefined ? (
                        <LandingPage msg={slugInvalidError} />
                      ) : (
                        <LandingPage msg="Somthing went wrong, please try after a while" />
                      )}

                    </>
                  }

                </>
              )}
            </>
          }

        </>
      }






    </>

  );
};

export default Home;
