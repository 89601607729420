import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./page/Home";
import { useNavigate, useParams } from "react-router-dom";
import { getGeoInfo } from "./service/Api";
import Routes from "./Routes";
import * as faceapi from 'face-api.js';

function App() {
  const navigate = useNavigate();
  const [geoLocationData, setGeoLocationData] = useState({
    countryName: "",
    countryCode: "",
  });
  const [effect , setEffect] = useState(false)
  const [modelsLoaded, setModelsLoaded] = useState(true);
  useEffect(() => {
    getGeoInfo().then((res) =>
      setGeoLocationData({
        countryName: res?.country_name,
        countryCode: res?.country_calling_code,
      })
    );
  }, []);
  const loadModels = async () => {
        
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
    await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
    await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
    await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');

};

useEffect(() => {
loadModels();
}, []);
  useEffect(() => {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });


    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  const [showoverlays, setShowOverLays] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);

  // useEffect(() => {
  //   const handleMouseEnter = () => setShowOverlay(false);
  //   const handleMouseLeave = () => setShowOverlay(true);
  //    let timeoutId = null;

  //    const handleMouseMove = () => {
  //      setShowOverlay(false);
  //      clearTimeout(timeoutId);
  //      timeoutId = setTimeout(() => {
  //        setShowOverlay(true);
  //      }, 60000); // 60 seconds
  //    };

  //   document.addEventListener("visibilitychange", () => {
  //     if (document.hidden) {
  //       setShowOverlay(true);
  //     } else {
  //       setShowOverlay(false);
  //     }
  //   });

  //   document.addEventListener("mouseenter", handleMouseEnter);
  //   document.addEventListener("mouseleave", handleMouseLeave);
  //    document.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     document.removeEventListener("mouseenter", () => {});
  //     document.removeEventListener("mouseleave", () => {});
  //     document.removeEventListener("mousemove", handleMouseMove);
  //     document.removeEventListener("visibilitychange", () => {});
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  return (
    <div
      className={`App`}
      onMouseLeave={() => {
        if (showoverlays == false) {
          // setShowOverLays(true)
        }
      }}
      onMouseEnter={() => {
        if (showoverlays == true) {
          // setShowOverLays(false)
        }
      }}
    >
      <Routes {...{ navigate, geoLocationData, showoverlays }} />
    </div>
  );
}

export default App;
