import { Viewer, SpecialZoomLevel, SetRenderRange, VisiblePagesRange } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import React, { forwardRef, useEffect, useRef, useState } from "react";
import FileViewer from 'react-file-viewer';
import './style.css'
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { PasswordStatus, PrimaryButton, TextBox } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { MoreIcon } from "@react-pdf-viewer/toolbar";
import ReactPlayer from 'react-player'
import 'react-jinke-music-player/assets/index.css';
import ReactJinkeMusicPlayer from 'react-jinke-music-player';
import img from './music-svgrepo-com (1).svg'
import { Player } from 'video-react';
import "video-react/dist/video-react.css"; // import css
import { ProgressBar } from '@react-pdf-viewer/core';
import axios from "axios";
import lottie from './lottie.gif'
import upload from './upload.svg'
import download from './download.svg'
import { FileIcon, defaultStyles } from 'react-file-icon';
import Loader from "../../../components/Loader";
import File from "../File";

const ProtectedView = ({ passwordStatus, verifyPassword }) => {
  //// console.log(passwordStatus, verifyPassword);
  const [password, setPassword] = React.useState("");
  const submit = () => verifyPassword(password);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "20rem",
        }}
      >
        <div
          style={{
            marginBottom: "0.5rem",
          }}
        >
          <TextBox
            placeholder="Enter the password ..."
            type="password"
            value={password}
            onChange={setPassword}
          />
        </div>
        {passwordStatus === PasswordStatus.WrongPassword && (
          <div
            style={{
              color: "#c02424",
              marginBottom: "0.5rem",
            }}
          >
            The password is invalid. Please try again!
          </div>
        )}
        <PrimaryButton onClick={submit}>Submit</PrimaryButton>
      </div>
    </div>
  );
};

const ViewerWrapper = forwardRef(
  (
    {
      renderProtectedView,
      fileUrl,
      fileType,
      fileName,
      custom,
      file_extension,
      renderPage,
      getFilePluginInstance,
      selectionModePluginInstance,
      allowprint,
      allowdwonload,
      handleDownload,
      slugFileData,
      size,
      getPdf,
    },
    ref
  ) => {
    const thumbnailPluginInstance = thumbnailPlugin();
    const rotatePluginInstance = rotatePlugin();
    const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;


    //// console.log(fileUrl)

    // const transform = (slot) => ({
    //   ...slot,
    //   Download: () => <></>,
    //   DownloadMenuItem: () => <></>,
    //   EnterFullScreen: () => <></>,
    //   EnterFullScreenMenuItem: () => <></>,
    //   SwitchTheme: () => <></>,
    //   SwitchThemeMenuItem: () => <></>,

    //   //    Print: () => <></>,

    //   Open: () => <></>,
    //   ShowSearchPopover: () => <></>,
    //   SwitchViewMode: () => <></>,
    //   OpenMenuItem: () => <></>,
    //   ShowPropertiesMenuItem: () => <></>,
    // });

    // const transformwithoutprint = (slot) => ({
    //   ...slot,
    //   Download: () => <></>,
    //   DownloadMenuItem: () => <></>,
    //   EnterFullScreen: () => <></>,
    //   EnterFullScreenMenuItem: () => <></>,
    //   SwitchTheme: () => <></>,
    //   SwitchThemeMenuItem: () => <></>,

    //   Print: () => <></>,

    //   Open: () => <></>,
    //   ShowSearchPopover: () => <></>,
    //   SwitchViewMode: () => <></>,
    //   OpenMenuItem: () => <></>,
    //   ShowPropertiesMenuItem: () => <></>,
    // });

    const renderToolbar = (Toolbar) => (
      // <Toolbar>
      //   {renderDefaultToolbar(
      //     allowprint === "true" ? transform : transformwithoutprint
      //   )}

      <Toolbar>
        {(props) => {
          const {
            CurrentPageInput,
            CurrentScale,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ZoomIn,
            ZoomOut,
            Print,
            Zoom,
          } = props;
          return (
            <>
              <GoToPreviousPage />
              <CurrentPageInput />
              <NumberOfPages />
              <GoToNextPage />

              {allowdwonload === "true" && (
                <div className="text-right flex justify-end">
                  <Download>
                    {(props) => (
                      <button
                        className="ml-0 test01"
                        onClick={() => {
                          handleDownload(props);
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          className="rpv-core__icon"
                          focusable="false"
                          height="16px"
                          viewBox="0 0 24 24"
                          width="16px"
                        >
                          <path d="M5.5,11.5c-.275,0-.341.159-.146.354l6.292,6.293a.5.5,0,0,0,.709,0l6.311-6.275c.2-.193.13-.353-.145-.355L15.5,11.5V1.5a1,1,0,0,0-1-1h-5a1,1,0,0,0-1,1V11a.5.5,0,0,1-.5.5Z"></path>
                          <path d="M23.5,18.5v4a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-4"></path>
                        </svg>
                      </button>
                    )}
                  </Download>
                </div>
              )}
              <div className="center-menu">
                <ZoomOut />
                <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} />

                <ZoomIn />
              </div>
              <div className="print_data">
                {allowprint === "true" && <Print />}
              </div>
            </>
          );
        }}
      </Toolbar>

      // </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
      thumbnailPlugin: {
        thumbnailWidth: 150,
      },
      sidebarTabs: (defaultTabs) => [
        // Remove the attachments tab (\`defaultTabs[2]\`)
        defaultTabs[0], // Bookmarks tab
        // defaultTabs[1], // Thumbnails tab
      ],
      renderToolbar,
    });

    const zoomPluginInstance = zoomPlugin();
    const { zoomTo } = zoomPluginInstance;

    const { DownloadButton, Download } = getFilePluginInstance;

    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const pdfRef = useRef();
    const [zoomLvl, setZoomLvl] = useState(1);
    const [showOverlay, setShowOverlay] = useState(true);

    useEffect(() => {
      var dist1 = 0;
      function start(ev) {
        if (ev.targetTouches.length == 2) {
          //check if two fingers touched screen
          dist1 = Math.hypot(
            //get rough estimate of distance between two fingers
            ev.touches[0].pageX - ev.touches[1].pageX,
            ev.touches[0].pageY - ev.touches[1].pageY
          );
        }
      }
      function move(ev) {
        if (ev.targetTouches.length == 2 && ev.changedTouches.length == 2) {
          // Check if the two target touches are the same ones that started
          var dist2 = Math.hypot(
            //get rough estimate of new distance between fingers
            ev.touches[0].pageX - ev.touches[1].pageX,
            ev.touches[0].pageY - ev.touches[1].pageY
          );
          //alert(dist);
          if (dist1 > dist2) {
            //if fingers are closer now than when they first touched screen, they are pinching
            setZoomLvl((prev) => prev - 0.01);
          }
          if (dist1 < dist2) {
            //if fingers are further apart than when they first touched the screen, they are making the zoomin gesture
            setZoomLvl((prev) => prev + 0.01);
          }
        }
      }

      document.addEventListener("touchstart", start, false);
      document.addEventListener("touchmove", move, false);

      return () => {
        document.removeEventListener("touchmove", () => { });
        document.removeEventListener("touchstart", () => { });
      };
    }, []);

    useEffect(() => {
      zoomTo(zoomLvl);
    }, [zoomLvl]);

    function pinchMove(e) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      if (
        touch1?.clientX &&
        touch2?.clientX &&
        touch1?.clientY &&
        touch2?.clientY
      ) {
        const dist = Math.hypot(
          touch1?.clientX - touch2?.clientX,
          touch1?.clientY - touch2?.clientY
        );

        // const scale = dist / 100;
        //// console.log(dist / 300);
        const zoomDiff = zoomLvl - dist / 300;
        if (zoomDiff <= 0) {
          zoomTo(zoomLvl - zoomDiff);
        } else {
          zoomTo(zoomLvl + zoomDiff);
        }
      }
    }
    function formatBytes(bytes, decimals = 2) {
      bytes = bytes * 1024;
      if (bytes === 0) return "0 Byte";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    const downloadFile = () => {

      handleDownload(fileUrl);

    };

    const allowedFileExtensions = [];
    function getFileIconUrl(extension) {
      const fileExtension = extension.toLowerCase();

      // Define file type mappings with their corresponding icons
      const fileTypes = {
        bmp: 'https://www.svgrepo.com/show/474871/image-file.svg',
        csv: 'https://www.svgrepo.com/show/375309/csv-document.svg',
        odt: 'https://www.svgrepo.com/show/375299/word-document.svg',
        doc: 'https://www.svgrepo.com/show/375299/word-document.svg',
        docx: 'https://www.svgrepo.com/show/375299/word-document.svg',
        gif: 'https://www.svgrepo.com/show/474871/image-file.svg',
        htm: 'https://www.svgrepo.com/show/375320/html-document.svg',
        html: 'https://www.svgrepo.com/show/375320/html-document.svg',
        jpg: 'https://www.svgrepo.com/show/474871/image-file.svg',
        jpeg: 'https://www.svgrepo.com/show/474871/image-file.svg',
        pdf: 'https://www.svgrepo.com/show/484943/pdf-file.svg',
        png: 'https://www.svgrepo.com/show/474871/image-file.svg',
        ppt: 'https://www.svgrepo.com/show/373991/powerpoint2.svg',
        pptx: 'https://www.svgrepo.com/show/373991/powerpoint2.svg',
        tiff: 'https://www.svgrepo.com/show/474871/image-file.svg',
        txt: 'https://www.svgrepo.com/show/375297/txt-document.svg',
        xls: 'https://www.svgrepo.com/show/375311/excel-document.svg',
        xlsx: 'https://www.svgrepo.com/show/375311/excel-document.svg',
        zip: 'https://www.svgrepo.com/show/375306/zip-document.svg',
        mp3: 'https://www.svgrepo.com/show/375308/audio-document.svg',
        wav: 'https://www.svgrepo.com/show/375308/audio-document.svg', // You might want to use a different icon for different types
        ogg: 'https://www.svgrepo.com/show/375308/audio-document.svg',
        aac: 'https://www.svgrepo.com/show/375308/audio-document.svg',

        webm: 'https://www.svgrepo.com/show/375301/video-document.svg',
        avi: 'https://www.svgrepo.com/show/375301/video-document.svg',
        mov: 'https://www.svgrepo.com/show/375301/video-document.svg',
        mkv: 'https://www.svgrepo.com/show/375301/video-document.svg',
        flv: 'https://www.svgrepo.com/show/375301/video-document.svg',
        wmv: 'https://www.svgrepo.com/show/375301/video-document.svg',
        mpeg: 'https://www.svgrepo.com/show/375301/video-document.svg',
        mp4: 'https://www.svgrepo.com/show/375301/video-document.svg',
      };

      // Check if the file extension exists in the mappings, otherwise default to 'file'
      return fileTypes[fileExtension] || 'https://www.svgrepo.com/show/485566/file.svg';
    }
    const audioExtensions = ['mp3', 'wav', 'ogg', 'aac']; // Add any other audio extensions you want to support
    const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'mkv', 'flv', 'wmv', 'mpeg'];

    // ...
    const [loadPercentage, setLoadPercentage] = useState(0);
    const [loadedImageSrc, setLoadedImageSrc] = useState(null);





    const [isHovered, setIsHovered] = useState(false);

    const setRenderRange: SetRenderRange = React.useCallback((visiblePagesRange: VisiblePagesRange) => {
      return {
        startPage: visiblePagesRange.endPage <= 20 ? 0 : visiblePagesRange.startPage - 5,
        endPage:
          visiblePagesRange.startPage <= 20
            ? Math.max(20, visiblePagesRange.endPage + 5)
            : visiblePagesRange.endPage + 5,
      };
    }, []);

    const [isImageLoaded, setImageLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [progress, setProgress] = useState(0);
    const isSvgRepoURL = (url) => {
      return url.startsWith("https://www.svgrepo.com");
    };

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Add any other image extensions you want to support
    useEffect(() => {
      if (file_extension && imageExtensions.includes(file_extension)) {
        const fetchImage = async () => {
          try {
            let url = fileUrl;
            if (!isSvgRepoURL(slugFileData?.document_details.thumbnail_url)) {
              url = slugFileData?.document_details.thumbnail_url;
            }
            const response = await axios.get(url, {
              responseType: 'blob',
              onDownloadProgress: (progressEvent) => {
                // Calculate the progress as a percentage
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
              },
              headers: {
                'Origin': 'https://viewer.grandtransfer.io',
                'Referer': 'https://viewer.grandtransfer.io/'
              }
            });

            const imageURL = URL.createObjectURL(response.data);
            setImageSrc(imageURL);

            setImageLoaded(true);
          } catch (error) {
            console.error("Error fetching the image:", error);
          }
        };

        fetchImage();
      }
    }, [fileUrl]);




    return (
      <>



        {

          <div

          >
            <div >
              {custom ?
                <>
                  <File
                    size={size}
                    fileType={fileType}
                    fileName={fileName}
                    fileUrl={fileUrl}
                    slugFileData={slugFileData}
                    file_extension={file_extension}
                    downloadFile={downloadFile}
                    formatBytes={formatBytes}
                    imageSrc={imageSrc}
                    progress={progress}
                    isImageLoaded={isImageLoaded}
                  />
                </>

                :
                <div className="hi">
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '4px',
                    }}
                  >
                    <div style={{ padding: '0px 2px' }}>
                      <RotateBackwardButton />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                      <RotateForwardButton />
                    </div>
                  </div>
                  <Viewer
                    ref={pdfRef}
                    initialPage={0}
                    enableSmoothScroll={true}
                    setRenderRange={setRenderRange}
                    fileUrl={fileUrl}
                    renderLoader={(percentages: number) => (
                      <div style={{ width: '340px' }}>
                        <Loader downloadProgress={Math.min(99, Math.round(percentages))} progress={true} />
                      </div>
                    )}
                    defaultScale={SpecialZoomLevel.PageFit}
                    plugins={[
                      rotatePluginInstance,
                      defaultLayoutPluginInstance,
                      thumbnailPluginInstance,
                      getFilePluginInstance,
                      zoomPluginInstance,
                      selectionModePluginInstance,
                      toolbarPluginInstance,
                    ]}
                    renderPage={renderPage}
                    renderProtectedView={(renderProps) => {
                      return <ProtectedView {...renderProps} />;
                    }}
                  // onZoom={(e) => setZoomLvl(e.scale)}
                  />
                </div>


              }





            </div>
          </div>}




      </>
    );
  }
);
export default React.memo(ViewerWrapper);
