import React, { useRef, useState, useEffect } from 'react';
import Webcam from "react-webcam";
import * as faceapi from 'face-api.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseURL } from '../../../service/Api/BaseUrl.constants';
import Loader from '../../../components/Loader';
const DetectPhotoComponent = (
    {

        slugFileData,
        error,
        setError,
        setOpenPdfByface,
    }
) => {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null); // Added for hidden canvas
    const [imageSrc, setImageSrc] = useState(null);
    const [hasFace, setHasFace] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [loading2, setLoading2] = useState(false);
    const [hasFaceandverify , sethasFaceandverify ] = useState(false)
    const [effect , setEffect] = useState(true)
    const [modelsLoaded, setModelsLoaded] = useState(true);


    setTimeout(()=>{
        setModelsLoaded(false)
    },7000)

  
    const imageRef = useRef(null);

    const detectFace = async (element) => {
        const detections = await faceapi.detectAllFaces(element, new faceapi.TinyFaceDetectorOptions());
        return detections.length > 0;
    };

    const captureAndVerifyFace = async () => {
        setLoading2(true)
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc)
        const userDescriptor = await getFaceDescriptor(imageSrc);
        
        if (userDescriptor && referenceDescriptor) {
          const isMatch = await isFaceMatching(userDescriptor, referenceDescriptor);
         // alert(isMatch ? "Face Verified!" : "Face does not match.");
          setLoading2(false)
          sethasFaceandverify(isMatch?true:false);
        } else {
                      setLoading2(false)
                      sethasFaceandverify(false);


         // alert("Failed to verify face. Ensure a face is in view or reference image is loaded correctly.");
        }
      };

    const capture = async () => {
        setLoading2(true)
        if(slugFileData?.ai_option=="WithReceivers")
        {
            captureAndVerifyFace()
        }
        else
        {

      
        const capturedImage = webcamRef.current.getScreenshot();
        setImageSrc(capturedImage);

        const image = new Image();
        image.src = capturedImage;
        image.onload = async () => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            imageRef.current.scrollIntoView({ behavior: 'smooth' });

            if (await detectFace(canvas)) {
                setHasFace(true);
            } else {
                setHasFace(false);
            }
            setLoading2(false)
        };
    }
    };




const [referenceDescriptor, setReferenceDescriptor] = useState(null);

useEffect(() => {
    if(slugFileData?.ai_option=="WithReceivers"){
  async function loadReferenceImage() {
    let photo  = null;
    if(slugFileData?.receiver?.photo)
    {
        photo = slugFileData?.receiver?.photo;
    }
    else
    {
        photo = slugFileData?.receivers?.photo;
    }
    const descriptor = await getFaceDescriptor(photo );
    setReferenceDescriptor(descriptor);
    // console.log(descriptor,"descriptor")
  }
  loadReferenceImage();

}
}, [slugFileData ,effect]);




    const handleSubmit = async () => {
        setIsLoading(true);
        setLoadingMessage('Verifying...');
        if(!imageSrc) {
            toast.error('No image to send!');
            return;
        }

        if(slugFileData?.ai_option=="With" && !hasFace )
        {
            toast.error('No Face Detected!');
            return;
        }

        if(slugFileData?.ai_option=="WithReceivers" && !hasFaceandverify )
        {
            toast.error('No Face Detected!');
            return;
        }

        const formData = new FormData();
        const blob = await fetch(imageSrc).then(res => res.blob());
        // console.log('Blob:', blob); // Debug blob
        
        formData.append('photo', blob);
        // console.log('Form Data:', formData.get('photo')); // Debug formData
    
        try {
            const response = await axios.post(
                baseURL + '/upload_person_image/' + slugFileData.user_id,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            setOpenPdfByface((res) => ({ ...res, faceMatch: true }));
            setOpenPdfByface((res) => ({ ...res, photo_url: response.data.photo_url }));
    
            // Handle your response data as needed
        } catch (error) {
            console.error('Failed to submit the photo:', error);
        }
        setIsLoading(false);

    };
    
    useEffect(() => {
        let timer;
        if (isLoading) {
            timer = setTimeout(() => {
                setLoadingMessage('Still working...');
            }, 5000);  // Change message after 5 seconds
        }
        return () => clearTimeout(timer);
    }, [isLoading]);
    
   
    const [hovered, setHovered] = useState(false);

    const baseStyle = {
        backgroundColor: !hovered ? '#fff' : '#992861',
        color: !hovered ? '#992861' : '#fff',
        transition: 'colors 300ms ease',
        position: 'absolute',
        bottom: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '0.5rem 1.25rem',
        borderRadius: '5%',
        outline: 'none',
        border: 'none'
    };

    function capitalizeFirstChar(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

    return (


        <>
        {modelsLoaded ? 
    <Loader message="Preparing..." /> : 
    <>
        
    <div
                style={{
                  padding: '1rem',
                  background: `white`, // Using a lighter shade of #992861
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                }}
              >

                <img
                  className="h-10 w-auto"
                  src="/GP-Logo-colored.svg"
                  alt=""
                />
                <div className="flex d-flex justify-items-between">



                </div>
              </div>




<div className="bg-gradient-to-r from-gray-300 to-grey-700 min-h-screen flex flex-col items-center justify-start py-4">
<div style={{ marginTop:"100px" }}>

<div className="mb-10 p-5 bg-white rounded-xl shadow-2xl w-80 text-center mt-4">
<h1 className="text-2xl font-semibold text-#992861 mb-3">
Access File Needed
</h1>
<p className="text-gray-700">
  Sender has requested a photo in{' '}
  {slugFileData?.ai_option == "WithReceivers" ? (
  <span
    dangerouslySetInnerHTML={{
      __html: ` for <strong>${capitalizeFirstChar(slugFileData.receiver?slugFileData.receiver.name : slugFileData.receivers.name)} </strong>`,
    }}
  ></span>
) : null}

  order for the file to be accessed.
</p>

</div>
{/* Webcam and capture button container */}
<div className="relative mb-10 w-72 h-72 border-4 border-blue-200 bg-white rounded-full shadow-2xl overflow-hidden">
<Webcam
audio={false}
ref={webcamRef}
screenshotFormat="image/jpeg"
className="absolute inset-0 w-full h-full object-cover"
/>

{/* Capture button */}
<button 
onClick={capture} 
style={baseStyle}
onMouseOver={() => setHovered(true)}
onMouseOut={() => setHovered(false)}
>
<svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24" 
width="20" 
height="20" 
fill="currentColor" 
className="inline-block mr-2"
>
<path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm7-5h-4l-1.5-2H10.5L9 3H5C3.34 3 2 4.34 2 6v12c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3zm1 14c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h3.5l1.5 2H15l1.5-2H19c.55 0 1 .45 1 1v12z"/>
</svg>
Capture
</button>

{/* Hidden canvas for processing */}
<canvas ref={canvasRef} className="hidden"></canvas>
</div>

{/* Face Detection Message and Image */}
{imageSrc && 
<div className="space-y-5 bg-white p-8 rounded-xl shadow-xl w-80  mb-4">
{loading2 ?<h2>Verifying...</h2> : 
<>
{slugFileData?.ai_option=="With" && <h2 className={`text-xl font-semibold text-center ${hasFace ? 'text-green-600' : 'text-red-600'}`}>
{(hasFace ) ? 'Face Detected!' : 'No Face Detected.'}
</h2>}

{slugFileData?.ai_option=="WithReceivers" && <h2 className={`text-xl font-semibold text-center ${hasFaceandverify ? 'text-green-600' : 'text-red-600'}`}>
{(hasFaceandverify ) ? 'Face Detected & Verified!' : 'Not Verified.'}
</h2>}
</>

}


{imageSrc && 
<div className="border border-gray-200 rounded overflow-hidden">
 <img 
                     id="imageContainer" 
                     ref={imageRef}
 
     src={imageSrc} 
     alt="Captured snapshot" 
     className="mx-auto w-full h-48 object-cover"
 />
 
</div>
}
</div>}
{/* Buttons */}
{imageSrc && <div className="flex space-x-4  items-center">


    {/* Reset button */}
    <button 
        onClick={() => {
            setImageSrc(null);
            setHasFace(false);
        }} 
        className=" text-white px-5 py-2 rounded-4 border-lg focus:outline-none"

        style={{
            color: 'black',
            padding: '0.5rem 1.25rem',
            backgroundColor: 'white'  ,
            borderRadius: '5px',
            outline: 'none'
        }}

    >
        Reset
    </button>

    {/* Submit button */}
    {!isLoading ? (
<button 
    onClick={handleSubmit}
    disabled={!imageSrc || (slugFileData?.ai_option=="With" && !hasFace) || (slugFileData?.ai_option=="WithReceivers" && !hasFaceandverify)}
    className=" text-white px-5 py-2 rounded-4 border-lg focus:outline-none"
    style={{
        color: '#fff',
        padding: '0.5rem 1.25rem',
        backgroundColor: '#992861',
        borderRadius: '5px',
        outline: 'none'
    }}
>
    Proceed 
</button>
) : (
<div className="flex items-center space-x-2">
    <Loader message={loadingMessage}/>
</div>
)}
</div>}


</div>
</div>

              </> }

        
        </>
       
  
    
    
    );
};
async function getFaceDescriptor(imageSrc) {
    const image = await faceapi.fetchImage(imageSrc);
    const detections = await faceapi.detectSingleFace(image).withFaceLandmarks().withFaceDescriptor();
    return detections?.descriptor;
  }
  
  function isFaceMatching(descriptor1, descriptor2) {
    const distance = faceapi.euclideanDistance(descriptor1, descriptor2);
    return distance < 0.6;
  }
export default DetectPhotoComponent;
