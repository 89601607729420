import _ from 'lodash';
import React, { Component } from 'react'
import ResizableRect from 'react-resizable-rotatable-draggable-touch-v2'
import styled from 'styled-components'

const DraggableStyle = styled.div`
  .rect.single-resizer {
    border:${props => props?.hideReactangle ? 'unset' : ''};
    .square {
      display:${props => props?.hideReactangle ? 'none' : 'block'};
    }
  }
`;

class Draggable extends Component {
  constructor() {
    super()
    this.state = {
      width: 250,
      height: 120,
      top: 100,
      left: 310,
      rotateAngle: 0
    }
  }

  componentDidMount() {
    const CHECKARRAY = _.find(this.props?.textWidth, { pageIndex: this.props?.pageIndex });

    if (CHECKARRAY) {
      this.setState({
        width: CHECKARRAY?.width,
        height: CHECKARRAY?.height,
        top: CHECKARRAY?.topCordinate,
        left: CHECKARRAY?.left,
        rotateAngle: CHECKARRAY?.rotation
      })
    }
  }

  handleResize = ({ style: { top, left, width, height }, isShiftKey, type, event }) => {
    this.setState({
      top: Math.round(top),
      left: Math.round(left),
      width: Math.round(width),
      height: Math.round(height)
    });

    const CHECKARRAY = _.find(this.props?.textWidth, { pageIndex: this.props?.pageIndex });
    if (CHECKARRAY) {
      CHECKARRAY['width'] = width;
      CHECKARRAY['height'] = height;
      const REJECTED_DATA = _.reject(this.props?.textWidth, CHECKARRAY);
      this.props.setTextWidth([...REJECTED_DATA, CHECKARRAY])
    } else {
      this.props.setTextWidth([...this.props.textWidth, { pageIndex: this.props?.pageIndex, width, height }])
    }
  }

  handleRotate = ({ rotateAngle, event }) => {
    this.setState({ rotateAngle });
    const CHECKARRAY = _.find(this.props?.textWidth, { pageIndex: this.props?.pageIndex });
    if (CHECKARRAY) {
      CHECKARRAY['rotation'] = rotateAngle;
      const REJECTED_DATA = _.reject(this.props?.textWidth, CHECKARRAY);
      this.props.setTextWidth([...REJECTED_DATA, CHECKARRAY])
    } else {
      this.props.setTextWidth([...this.props.textWidth, { pageIndex: this.props?.pageIndex, rotation: rotateAngle }])
    }
  }

  handleDrag = ({ deltaX, deltaY, event }) => {
    if (!this.props.hideReactangle) {
      this.setState({
        left: this.state.left + deltaX,
        top: this.state.top + deltaY
      });
      const CHECKARRAY = _.find(this.props?.textWidth, { pageIndex: this.props?.pageIndex });
      if (CHECKARRAY) {
        CHECKARRAY['topCordinate'] = this.state.top + deltaY;
        CHECKARRAY['left'] = this.state.left + deltaX;
        const REJECTED_DATA = _.reject(this.props?.textWidth, CHECKARRAY);
        this.props.setTextWidth([...REJECTED_DATA, CHECKARRAY])
      } else {
        this.props.setTextWidth([...this.props.textWidth, { pageIndex: this.props?.pageIndex, topCordinate: this.state.top + deltaY, left: this.state.left + deltaX }])
      }
    }
  }
  render() {

    const { width, top, left, height, rotateAngle } = this.state;

    return (
      <DraggableStyle className="App" hideReactangle={this.props.hideReactangle}>
        <ResizableRect
          left={left}
          top={top}
          width={width}
          height={height}
          rotateAngle={rotateAngle}
          // aspectRatio={false}
          minWidth={250}
          minHeight={120}
          zoomable='n, w, s, e, nw, ne, se, sw'
          rotatable={!this.props.hideReactangle}
          // onRotateStart={this.handleRotateStart}
          onRotate={this.handleRotate}
          // onRotateEnd={this.handleRotateEnd}
          // onResizeStart={this.handleResizeStart}
          onResize={this.handleResize}
          // onResizeEnd={this.handleUp}
          // onDragStart={this.handleDragStart}
          onDrag={this.handleDrag}
          // onDragEnd={this.handleDragEnd}
          color="#f51313"
        >
          {(this.props?.image !== "" && this.props?.image !== null)
            ?
            <img
              style={{ width: "100%", opacity: `${(this.props?.image !== "" && this.props?.image !== null) ? this.props?.opacity : 1}` }}
              className="h-full"
              src={this.props?.image} width={250} height={120}
            />
            :
            <div
              className="h-full"
              style={{
                color: `${this.props.color}`,
                fontSize: `${this.props?.fontSize}px`,
                fontWeight: "bold",
                textTransform: "uppercase",
                display: 'flex',
                alignItems: 'center',
                height: this.state.height,
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              {this.props?.watermark}
            </div>
          }
        </ResizableRect>
      </DraggableStyle>
    )
  }
}

export default Draggable;